<template>
  <!-- TODO:: 연수원, 체육시설, 스튜디오 컴포넌트화 필요.  -->
  <main class="kb-main" id="kb-facility">
    <lxp-main-header :show-back="true" :show-title="true" title="생활연수 신청">
      <template v-slot:action>
        <!-- main-header > header-tab -->
        <div class="header-tab">
          <nav class="kb-nav-round-tabs">
            <ul class="kb-nav-list">
              <li class="kb-nav-item">
                <a href="javascript:" class="kb-nav-link" :class="{ 'is-active': reserveVenue === '연수원' }"
                   @click="reserveVenue = '연수원'"><span class="kb-nav-text">연수원</span></a>
              </li>
              <li class="kb-nav-item">
                <a href="javascript:" class="kb-nav-link" :class="{ 'is-active': reserveVenue === '체육시설' }"
                   @click="reserveVenue = '체육시설'"><span class="kb-nav-text">체육시설</span></a>
              </li>
              <!--              <li class="kb-nav-item">
                <a href="javascript:" class="kb-nav-link" :class="{'is-active': reserveVenue === '스튜디오'}"
                   @click="reserveVenue = '스튜디오'"><span class="kb-nav-text">스튜디오</span></a>
              </li>-->
            </ul>
          </nav>
        </div>
      </template>
    </lxp-main-header>
    <!-- main-content -->
    <div class="main-content min-component">
      <!-- 임시로 해놓은거 추후에는 데이터만 바뀌기 때문에 데이터만 갈아끼우는 방식으로 코딩 -->
      <div class="facility-home-container">
        <!-- content-section: 공지사항 -->
        <NoticeComponent @updatePopupNotice="updatePopupNotice"/>
        <!-- content-section: 신청현황 -->
        <HistoryComponent ref="historyComponent" v-if="reserveVenue === '연수원'" />
        <!-- content-section: 생활연수 바로가기 -->
        <HistorySportsComponent ref="historySportsComponent" v-if="reserveVenue === '체육시설'" />

        <div class="kb-table-bottom bottom-buttons" style="margin-bottom: 50px">
          <button @click="goNext('step0')" type="button" class="kb-btn kb-btn-primary kb-btn-xl">
            <span class="text">{{ reserveVenue }} 이용신청</span>
          </button>
        </div>

        <DirectLinkComponent />

      </div>

      <!-- apply-container -->
      <div class="apply-container">
        <!-- TODO:: Template 부분 컴포넌트화 필요.  -->

        <template v-if="reserveVenue === '연수원'">
          <!-- begin:popup-container 연수원 신청 하기 0-->
          <div class="popup-container" :class="{ 'is-active': trainingInstituteApplyStep0Modal }">
            <!-- popup -->
            <div class="popup" id="popup-facility-k10-2">
              <!-- popup > popup-inner -->
              <div class="popup-inner">
                <form action="">
                  <!-- popup-inner > popup-header -->
                  <header class="popup-header">
                    <h3 class="title">연수원 이용 신청하기</h3>
                    <p class="subtext">설렘과 웃음이 가득한 속초, 대천연수원에 오셔서 즐겁고 행복한 시간 보내시길 바랍니다.</p>
                  </header>
                  <!-- popup-inner > popup-content -->
                  <div class="popup-content">

                    <div class="resv-container">
                      <div class="resv-top" v-html="trnctPopupNotice"></div>
                      <div class="resv-content">
                        <!-- apply-container -->
                        <div class="apply-container">
                          <!-- apply-images -->
                          <ImageSwiperComponent :image-list="trainingArea" />
                          <!-- apply-content -->
                          <div class="apply-content">
                            <form @submit.prevent>
                              <!-- apply-form -->
                              <div class="apply-form">
                                <!-- apply-kb-form-field : 연수원 선택 -->
                                <div class="apply-kb-form-field">
                                  <h4 class="field-title">연수원 선택</h4>
                                  <div class="kb-form-box-group">
                                    <div class="kb-form-box">
                                      <input type="radio" class="kb-form-box-input" name="chk_01" value="1"
                                             v-model="venue" />
                                      <label for="chk_01_1" class="kb-form-box-label">속초 연수원</label>
                                    </div>
                                    <div class="kb-form-box">
                                      <input type="radio" class="kb-form-box-input" name="chk_01" value="2"
                                             v-model="venue" />
                                      <label for="chk_01_2" class="kb-form-box-label">대천 연수원</label>
                                    </div>
                                    <!--                    <div class="kb-form-box">
                                                          <input type="radio" class="kb-form-box-input" name="chk_01" id="chk_01_3" value="3"
                                                                 v-model="venue"
                                                          />
                                                          <label for="chk_01_3" class="kb-form-box-label">강원 연수원</label>
                                                        </div>-->
                                  </div>
                                </div>

                                <div class="apply-kb-form-field">
                                  <h4 class="field-title">방 유형 선택</h4>
                                  <div class="kb-form-box-group">
                                    <div class="kb-form-box" v-if="venue == 2">
                                      <input type="radio" class="kb-form-box-input" name="chk_02" value="OR"
                                             v-model="room" />
                                      <label for="chk_01_1" class="kb-form-box-label">원룸</label>
                                    </div>
                                    <div class="kb-form-box">
                                      <input type="radio" class="kb-form-box-input" name="chk_02" value="TR"
                                             v-model="room" />
                                      <label for="chk_01_2" class="kb-form-box-label">투룸</label>
                                    </div>
                                    <div class="kb-form-box">
                                      <input type="radio" class="kb-form-box-input" name="chk_02" value="LTR"
                                             v-model="room" />
                                      <label for="chk_01_3" class="kb-form-box-label">안식년 (투룸)</label>
                                    </div>
                                  </div>
                                </div>

                                <!-- apply-kb-form-field : 투숙기간 선택 -->
                                <div class="apply-kb-form-field">
                                  <h4 class="field-title">투숙기간 선택</h4>
                                  <!-- kb-form-period -->
                                  <div class="kb-form-period">
                                    <!-- kb-form-period > kb-form-period-date -->
                                    <!--                    <KbDatepickerRange v-model="schedule" />-->
                                    <!--                    <KbDatepicker v-model="schedule"/>-->

                                    <KbCalender v-model="schedule" :trnct-mst-sn="venue" :room-ty="room" />
                                    <!-- kb-form-period > kb-form-period-date -->
                                  </div>
                                  <!-- //kb-form-period -->
                                </div>

                                <div class="apply-kb-form-field">
                                  <button type="button" @click="trainingInstituteApplyBtn(true)"
                                          class="kb-btn kb-btn-primary kb-btn-wide"><span class="text">연수원 신청하기</span></button>
                                </div>

                                <!-- apply-kb-form-field : 목적 선택 임시로 배제한다 -->
                                <!--                <div class="apply-kb-form-field">
                                                  <h4 class="field-title">목적 선택</h4>
                                                  <div class="kb-form-box-group">
                                                    <div class="kb-form-box" :class="[ isPersonal ? 'is-active' : 'is-inactive' ]">
                                                      <button class="kb-form-box-btn" @click="trainingInstituteApplyBtn(true)"><span class="text">개인 신청 선택</span></button>
                                                    </div>
                                                    <div class="kb-form-box" :class="[ !isPersonal ? 'is-active' : 'is-inactive' ]">
                                                      <button class="kb-form-box-btn" @click="trainingInstituteApplyBtn(false)"><span class="text">부점 신청 선택</span></button>
                                                    </div>
                                                  </div>
                                                </div>-->
                                <!-- apply-kb-form-field : 인원 선택 -->
                                <!--                <div class="apply-kb-form-field">
                                                  <h4 class="field-title">인원 선택</h4>
                                                  <div class="kb-form-quantity">
                                                    <button class="kb-btn-control"><i class="icon-minus" @click="handleSubtractPeople"></i></button>
                                                    <input type="number" class="kb-form-control-plaintext" v-model="peopleCnt" readonly/>
                                                    <button class="kb-btn-control"><i class="icon-plus" @click="handleAddPeople"></i></button>
                                                  </div>
                                                </div>-->
                                <!--                <div class="apply-kb-form-field">
                                                  <button class="kb-btn kb-btn-primary kb-btn-wide" @click="trainingInstituteApplyBtn"><span class="text">다음</span></button>
                                                </div>-->
                              </div>
                            </form>
                          </div>


                        </div>
                        <!-- //apply-container -->
                      </div>
                    </div>

                  </div>
                </form>
              </div>
              <!-- popup > popup-close -->
              <div class="popup-close">
                <button @click="trainingInstituteApplyStep0Modal = false" type="button" class="kb-btn-popup-close"><i
                    class="icon-popup-close">팝업닫기</i></button>
              </div>
            </div>
            <!-- //popup -->
          </div>
          <!-- end:popup-container -->
        </template>
        <template v-if="reserveVenue === '체육시설'">

          <div class="popup-container" :class="{ 'is-active': trainingInstituteApplyStep0Modal }">
            <!-- popup -->
            <div class="popup" id="popup-facility-k10-2">
              <!-- popup > popup-inner -->
              <div class="popup-inner">
                <form action="">
                  <!-- popup-inner > popup-header -->
                  <header class="popup-header">
                    <h3 class="title">체육시설 이용 신청하기</h3>
                  </header>
                  <!-- popup-inner > popup-content -->
                  <div class="popup-content">

                    <div class="resv-container">
                      <div class="resv-content">
                        <!-- apply-container -->
                        <div class="apply-container">
                          <!-- apply-images -->
                          <ImageSwiperComponent :image-list="ptArea" />
                          <!-- apply-content -->
                          <div class="apply-content">
                            <form @submit.prevent>
                              <!-- apply-form -->
                              <div class="apply-form">
                                <!-- apply-kb-form-field : 체육시설 선택 -->
                                <div class="apply-kb-form-field">
                                  <h4 class="field-title">체육시설 선택</h4>
                                  <div class="kb-form-box-group">
                                    <div class="kb-form-box">
                                      <input type="radio" class="kb-form-box-input" name="chk_01">
                                      <label for="chk_01_1" class="kb-form-box-label">일산 운동장</label>
                                    </div>
                                    <!--                    <div class="kb-form-box">
                                                          <input type="radio" class="kb-form-box-input" name="chk_01" id="chk_01_2">
                                                          <label for="chk_01_2" class="kb-form-box-label">대천 운동장</label>
                                                        </div>
                                                        <div class="kb-form-box">
                                                          <input type="radio" class="kb-form-box-input" name="chk_01" id="chk_01_3">
                                                          <label for="chk_01_3" class="kb-form-box-label">강원 운동장</label>
                                                        </div>-->
                                  </div>
                                </div>
                                <!-- apply-kb-form-field : 예약일시 선택 -->
                                <div class="apply-kb-form-field">
                                  <h4 class="field-title">예약일시 선택</h4>
                                  <!-- kb-form-period -->
                                  <div class="kb-form-period">
                                    <kb-datepicker-time v-if="data.initTimePicker" v-model="schedule2"
                                                        :btn-fn="clickTime" />
                                  </div>
                                  <!-- //kb-form-period -->
                                </div>
                                <div class="apply-kb-form-field">
                                  <button class="kb-btn kb-btn-primary kb-btn-wide"><span class="text">검색</span></button>
                                </div>
                              </div>
                            </form>
                          </div>


                        </div>
                        <!-- //apply-container -->
                      </div>
                    </div>

                  </div>
                </form>
              </div>
              <!-- popup > popup-close -->
              <div class="popup-close">
                <button @click="trainingInstituteApplyStep0Modal = false" type="button" class="kb-btn-popup-close"><i
                    class="icon-popup-close">팝업닫기</i></button>
              </div>
            </div>
            <!-- //popup -->
          </div>

        </template>
        <template v-if="reserveVenue === '스튜디오'">
          <ImageSwiperComponent :image-list="studio" />
          <div class="apply-content">
            <form @submit.prevent>
              <!-- apply-form -->
              <div class="apply-form">
                <!-- apply-kb-form-field : 스튜디오 선택 -->
                <div class="apply-kb-form-field">
                  <h4 class="field-title">스튜디오 선택</h4>
                  <div class="kb-form-box-group">
                    <div class="kb-form-box">
                      <input type="radio" class="kb-form-box-input" name="chk_01" id="chk_01_1" checked="">
                      <label for="chk_01_1" class="kb-form-box-label">여의도 팔레르모</label>
                    </div>
                    <div class="kb-form-box">
                      <input type="radio" class="kb-form-box-input" name="chk_01" id="chk_01_2">
                      <label for="chk_01_2" class="kb-form-box-label">여의도 ACE</label>
                    </div>
                    <div class="kb-form-box">
                      <input type="radio" class="kb-form-box-input" name="chk_01" id="chk_01_3">
                      <label for="chk_01_3" class="kb-form-box-label">여의도 나폴리</label>
                    </div>
                    <div class="kb-form-box">
                      <input type="radio" class="kb-form-box-input" name="chk_01" id="chk_01_3">
                      <label for="chk_01_3" class="kb-form-box-label">천안</label>
                    </div>
                  </div>
                </div>
                <!-- apply-kb-form-field : 예약일시 선택 -->
                <div class="apply-kb-form-field">
                  <h4 class="field-title">예약일시 선택</h4>
                  <!-- kb-form-period -->
                  <div class="kb-form-period">
                    <!-- kb-form-period > kb-form-period-date -->
                    <div class="kb-form-period-date dropdown" data-id="dropdown-calendar-picker">
                      <button class="kb-btn kb-btn-outline rounded-lg dropdown-btn"><span class="text">예약일시</span></button>
                      <div class="dropdown-target">
                        <div class="dropdown-box">
                          <!-- calendar-picker-container -->
                          <div class="calendar-picker-container">
                            <!-- calendar-picker-container > calendar-picker -->
                            <div class="calendar-picker">
                              <div class="picker-body">
                                <div class="datepicker-header">
                                  <button class="kb-btn-datepicker-nav kb-btn-datepicker-prev"><i class="icon-prev"></i>
                                  </button>
                                  <div class="title">2021년 12월</div>
                                  <button class="kb-btn-datepicker-nav kb-btn-datepicker-next"><i class="icon-next"></i>
                                  </button>
                                </div>
                                <div class="datepicker-table">
                                  <table>
                                    <thead>
                                    <tr>
                                      <th>일</th>
                                      <th>월</th>
                                      <th>화</th>
                                      <th>수</th>
                                      <th>목</th>
                                      <th>금</th>
                                      <th>토</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>
                                        <button class="kb-btn-day" disabled=""><span class="text">1</span></button>
                                      </td>
                                      <td>
                                        <button class="kb-btn-day" disabled=""><span class="text">2</span></button>
                                      </td>
                                      <td>
                                        <button class="kb-btn-day" disabled=""><span class="text">3</span></button>
                                      </td>
                                      <td>
                                        <button class="kb-btn-day" disabled=""><span class="text">4</span></button>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <button class="kb-btn-day"><span class="text">5</span></button>
                                      </td>
                                      <td>
                                        <button class="kb-btn-day"><span class="text">6</span></button>
                                      </td>
                                      <td>
                                        <button class="kb-btn-day"><span class="text">7</span></button>
                                      </td>
                                      <td>
                                        <button class="kb-btn-day"><span class="text">8</span></button>
                                      </td>
                                      <td>
                                        <button class="kb-btn-day"><span class="text">9</span></button>
                                      </td>
                                      <td>
                                        <button class="kb-btn-day"><span class="text">10</span></button>
                                      </td>
                                      <td>
                                        <button class="kb-btn-day"><span class="text">11</span></button>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <button class="kb-btn-day"><span class="text">12</span></button>
                                      </td>
                                      <td>
                                        <button class="kb-btn-day"><span class="text">13</span></button>
                                      </td>
                                      <td>
                                        <button class="kb-btn-day"><span class="text">14</span></button>
                                      </td>
                                      <td>
                                        <button class="kb-btn-day"><span class="text">15</span></button>
                                      </td>
                                      <td>
                                        <button class="kb-btn-day"><span class="text">16</span></button>
                                      </td>
                                      <td>
                                        <button class="kb-btn-day"><span class="text">17</span></button>
                                      </td>
                                      <td>
                                        <button class="kb-btn-day"><span class="text">18</span></button>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <button class="kb-btn-day"><span class="text">19</span></button>
                                      </td>
                                      <td>
                                        <button class="kb-btn-day"><span class="text">20</span></button>
                                      </td>
                                      <td>
                                        <button class="kb-btn-day"><span class="text">21</span></button>
                                      </td>
                                      <td>
                                        <button class="kb-btn-day"><span class="text">22</span></button>
                                      </td>
                                      <td>
                                        <button class="kb-btn-day is-active"><span class="text">23</span></button>
                                      </td>
                                      <td>
                                        <button class="kb-btn-day"><span class="text">24</span></button>
                                      </td>
                                      <td>
                                        <button class="kb-btn-day"><span class="text">25</span></button>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <button class="kb-btn-day"><span class="text">26</span></button>
                                      </td>
                                      <td>
                                        <button class="kb-btn-day"><span class="text">27</span></button>
                                      </td>
                                      <td>
                                        <button class="kb-btn-day"><span class="text">28</span></button>
                                      </td>
                                      <td>
                                        <button class="kb-btn-day"><span class="text">29</span></button>
                                      </td>
                                      <td>
                                        <button class="kb-btn-day"><span class="text">30</span></button>
                                      </td>
                                      <td>
                                        <button class="kb-btn-day"><span class="text">31</span></button>
                                      </td>
                                      <td>&nbsp;</td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div class="calendar-time">
                              <div class="time-header">
                                <div class="title">23일</div>
                              </div>
                              <div class="time-contents">
                                <ul class="time-list">
                                  <li class="time-item">
                                    <div class="time"><span class="time-text">09:00 - 10:00</span></div>
                                    <a class="link"><span class="link-text">예약완료</span><i class="icon-arrow"></i></a>
                                  </li>
                                  <li class="time-item is-active">
                                    <div class="time"><span class="time-text">10:00 - 11:00</span></div>
                                    <a href="javascript:" class="link"><span class="link-text">예약</span><i
                                        class="icon-arrow"></i></a>
                                  </li>
                                  <li class="time-item">
                                    <div class="time"><span class="time-text">11:00 - 12:00</span></div>
                                    <a class="link"><span class="link-text">예약완료</span><i class="icon-arrow"></i></a>
                                  </li>
                                  <li class="time-item is-active">
                                    <div class="time"><span class="time-text">13:00 - 14:00</span></div>
                                    <a href="javascript:" class="link"><span class="link-text">예약</span><i
                                        class="icon-arrow"></i></a>
                                  </li>
                                  <li class="time-item">
                                    <div class="time"><span class="time-text">14:00 - 15:00</span></div>
                                    <a class="link"><span class="link-text">예약완료</span><i class="icon-arrow"></i></a>
                                  </li>
                                  <li class="time-item">
                                    <div class="time"><span class="time-text">15:00 - 16:00</span></div>
                                    <a class="link"><span class="link-text">예약완료</span><i class="icon-arrow"></i></a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <!-- //calendar-picker-container -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- //kb-form-period -->
                </div>
                <div class="apply-kb-form-field">
                  <button class="kb-btn kb-btn-primary kb-btn-wide"><span class="text">검색</span></button>
                </div>
              </div>
            </form>
          </div>
        </template>
        <!-- //apply-container -->
      </div>
    </div>
    <!-- //main-content -->

    <!-- begin:popup-container -->


    <!-- 연수원 이용 신청하기 1 -->
    <div v-if="trainingInstituteApplyStep1Modal" class="popup-container is-active">
      <!-- popup -->
      <div class="popup" id="popup-facility-k02">
        <!-- popup > popup-inner -->
        <div class="popup-inner">
          <form @submit.prevent>
            <!-- popup-inner > popup-header -->
            <header class="popup-header">
              <h3 class="title">연수원 이용 신청하기</h3>
            </header>
            <!-- popup-inner > popup-content -->
            <div class="popup-content">
              <div class="facility-resve">
                <!-- facility-resve-section -->
                <div class="facility-resve-section">
                  <div class="kb-table kb-table-striped">
                    <table>
                      <colgroup>
                        <col />
                      </colgroup>
                      <thead>
                      <tr>
                        <th><span class="th-title">신청자 정보</span></th>
                        <th><span class="th-title">연수원</span></th>
                        <th><span class="th-title">입소일</span></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td><span class="td-text">{{ session.deptNm }} ・ {{ session.jbgdNm }} ・ {{ session.lrnerNm
                          }}</span></td>
                        <td><span class="td-text"> {{ venue == '1' ? '속초연수원' : '대천연수원' }}</span></td>
                        <td><span class="td-text"> {{ schedule.startDt.ymdformat }}</span></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- facility-resve-section -->
                <div class="facility-resve-section section-divider">
                  <div class="kb-form-fields">
                    <!-- kb-form-row -->
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">숙실 형태</span>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <div class="kb-form-check-group">
                          <div class="kb-form-check kb-form-check-inline">
                            <input disabled type="radio" v-model="room" class="kb-form-check-input" name="room" value="OR" />
                            <label for="" class="kb-form-check-label"><span class="text">원룸</span></label>
                          </div>
                          <div class="kb-form-check kb-form-check-inline">
                            <input disabled type="radio" v-model="room" class="kb-form-check-input" name="room" value="TR" />
                            <label for="" class="kb-form-check-label"><span class="text">투룸</span></label>
                          </div>
                          <div class="kb-form-check kb-form-check-inline">
                            <input disabled type="radio" v-model="room" class="kb-form-check-input" name="room"
                                   value="LTR" />
                            <label for="" class="kb-form-check-label"><span class="text">안식년 (투룸)</span></label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- kb-form-row -->
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">이용기간 선택</span>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <div class="kb-form-dropdown" :class="{ 'is-active': toggles.periodOpen }">
                          <button class="kb-form-dropdown-btn" @click="openPeriod"><strong class="text">선택</strong><i
                              class="icon-arrow"></i></button>
                          <div class="kb-form-dropdown-options">
                            <div class="dropdown-option">
                              <ul class="dropdown-option-list">
                                <li class="dropdown-option-item" v-for="item in usePeridOption" :key="item.value">
                                  <a href="javascript:" class="dropdown-option-link" @click="selectUsePeriod(item)">
                                    <span class="dropdown-option-text">{{ item.label }}</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="kb-form-column">
                        <span>{{ data.display }}</span>
                      </div>
                    </div>
                    <!-- //kb-form-row -->
                  </div>
                </div>
              </div>
            </div>
            <!-- popup-inner > popup-buttons -->
            <div class="popup-buttons">
              <button class="kb-btn kb-btn-outline kb-btn-lg" @click="trainingInstituteApplyStep1Modal = false"><span
                  class="text">취소</span></button>
              <a href="javascript:" class="kb-btn kb-btn-primary kb-btn-lg" @click="goNext('step2')"><span
                  class="text">다음</span></a>
            </div>
          </form>
        </div>
        <!-- popup > popup-close -->
        <div class="popup-close">
          <button class="kb-btn-popup-close" @click="trainingInstituteApplyStep1Modal = false"><i
              class="icon-popup-close">팝업닫기</i></button>
        </div>
      </div>
      <!-- //popup -->
    </div>
    <!-- end:popup-container -->

    <!-- begin:popup-container -->
    <!-- 연수원 이용 신청하기 2 -->
    <div class="popup-container" :class="{ 'is-active': trainingInstituteApplyStep2Modal }">
      <!-- popup -->
      <div class="popup" id="popup-facility-k02">
        <!-- popup > popup-inner -->
        <div class="popup-inner">
          <form @submit.prevent>
            <!-- popup-inner > popup-header -->
            <header class="popup-header">
              <h3 class="title">연수원 이용 신청하기</h3>
            </header>
            <!-- popup-inner > popup-content -->
            <div class="popup-content">
              <div class="facility-resve">
                <!-- facility-resve-section -->
                <section class="popup-section facility-resve-section">
                  <header class="section-header">
                    <h4 class="title">신청안내</h4>
                  </header>
                  <div class="segment-box">
                    <p class="text">신청 진행 중입니다. 아래 세부 정보 입력후 [최종 신청] 하셔야 합니다.</p>
                    <p class="text">동반가족 필수 입력 (입력된 가족 외 입소 제한)</p>
                    <p class="text">직원간 이용은 코로나 19 감염 예방을 위해 별도 통보시까지 제한</p>
                  </div>
                </section>
                <!-- facility-resve-section -->
                <section class="popup-section facility-resve-section">
                  <header class="section-header">
                    <h4 class="title">신청정보</h4>
                  </header>
                  <div class="kb-form-fields">
                    <!-- kb-form-row -->
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">비상 연락처</span>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <input maxlength="12" v-model.trim="trnctAplyIns.emerCt" type="tel" class="kb-form-control"
                               placeholder="''-' 없이 숫자만으로 비상연락처를 입력하세요.'" />
                      </div>
                    </div>
                    <!-- kb-form-row -->
                    <div class="kb-form-row" v-if="trnctAplyIns.prsnBrcYn == 'Y'">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">차량번호</span>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <input v-model="trnctAplyIns.carNo" type="text" class="kb-form-control" placeholder="차량번호를 입력하세요." />
                      </div>
                    </div>

                    <div class="kb-form-row" v-if="trnctAplyIns.prsnBrcYn == 'N'">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">서무직원번호</span>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <input v-model="trnctAplyIns.mblTelno" type="text" class="kb-form-control"
                               placeholder="서무직원 번호를 입력하세요." />
                      </div>
                    </div>

                    <div class="kb-form-row" v-if="trnctAplyIns.prsnBrcYn == 'N'">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">신청숙실수</span>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <input v-model="trnctAplyIns.useRoomCnt" type="number" class="kb-form-control"
                               placeholder="신청 숙실수." />
                      </div>
                    </div>

                    <div class="kb-form-row" v-if="trnctAplyIns.prsnBrcYn == 'N'" style="align-items: center;">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">입소인원</span>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <label class="kb-form-label kb-form-column-title">
                          <span class="kb-form-label-text">남자</span>
                        </label>
                      </div>
                      <div class="kb-form-column" style="display: flex;align-items: baseline;">
                        <input v-model="trnctAplyIns.mlePeopl" type="text" class="kb-form-control"
                               placeholder="" /><span>명</span>
                      </div>

                      <div class="kb-form-column">
                        <label class="kb-form-label kb-form-column-title">
                          <span class="kb-form-label-text">여자</span>
                        </label>
                      </div>
                      <div class="kb-form-column" style="display: flex;align-items: baseline;">
                        <input v-model="trnctAplyIns.femPeopl" type="text" class="kb-form-control"
                               placeholder="" /><span>명</span>
                      </div>
                    </div>

                    <div v-if="trnctAplyIns.prsnBrcYn == 'Y'">
                      <div class="kb-form-row">
                        <div class="kb-form-column kb-form-column-title">
                          <label class="kb-form-label">
                            <span class="kb-form-label-text">입소자 1</span>
                          </label>
                        </div>
                        <div class="kb-form-column">
                          <input v-model="trnctAplyIns.aplyDtl[0].cpnnNm" type="text" class="kb-form-control"
                                 placeholder="이름을 입력하세요." />
                        </div>
                        <!--                        <div class="kb-form-column kb-form-column-title">
                          <label class="kb-form-label">
                            <span class="kb-form-label-text"> {{ getRelName(trnctAplyIns.aplyDtl[0].aplcntRel) }}</span>
                          </label>
                        </div>-->
                        <div class="kb-form-column">
                          <div class="kb-form-dropdown" :class="{ 'is-active': toggles.aply1 }">
                            <button class="kb-form-dropdown-btn" @click="openAply(1)"><strong class="text">{{
                                getRelName(trnctAplyIns.aplyDtl[0].aplcntRel) }}</strong><i
                                class="icon-arrow"></i></button>
                            <div class="kb-form-dropdown-options">
                              <div class="dropdown-option">
                                <ul class="dropdown-option-list">
                                  <li class="dropdown-option-item" v-for="item in familyList" :key="item.code">
                                    <a href="javascript:" class="dropdown-option-link" @click="selectAply(0, item.code)">
                                      <span class="dropdown-option-text">{{ item.label }}</span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="kb-form-column w-220 d-flex justify-content-end">
                          <button type="button" class="kb-btn-text">
                            <strong class="font-body14B text-red" style="visibility: hidden">삭제</strong>
                          </button>
                        </div>

                      </div>
                      <div class="kb-form-row">
                        <div class="kb-form-column kb-form-column-title">
                          <label class="kb-form-label">
                            <span class="kb-form-label-text">입소자 2</span>
                          </label>
                        </div>
                        <div class="kb-form-column">
                          <input v-model="trnctAplyIns.aplyDtl[1].cpnnNm" type="text" class="kb-form-control"
                                 placeholder="이름을 입력하세요." />
                        </div>
                        <!--                        <div class="kb-form-column kb-form-column-title">
                          <label class="kb-form-label">
                            <span class="kb-form-label-text"> {{ getRelName(trnctAplyIns.aplyDtl[1].aplcntRel )}}</span>
                          </label>
                        </div>-->
                        <div class="kb-form-column">
                          <div class="kb-form-dropdown" :class="{ 'is-active': toggles.aply2 }">
                            <button class="kb-form-dropdown-btn" @click="openAply(2)"><strong class="text">{{
                                getRelName(trnctAplyIns.aplyDtl[1].aplcntRel) }}</strong><i
                                class="icon-arrow"></i></button>
                            <div class="kb-form-dropdown-options">
                              <div class="dropdown-option">
                                <ul class="dropdown-option-list">
                                  <li class="dropdown-option-item" v-for="item in familyList" :key="item.code">
                                    <a href="javascript:" class="dropdown-option-link" @click="selectAply(1, item.code)">
                                      <span class="dropdown-option-text">{{ item.label }}</span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="kb-form-column w-220 d-flex justify-content-end">
                          <button type="button" class="kb-btn-text" @click="deleteFamily(1)">
                            <strong class="font-body14B text-red">삭제</strong>
                          </button>
                        </div>
                      </div>
                      <div class="kb-form-row">
                        <div class="kb-form-column kb-form-column-title">
                          <label class="kb-form-label">
                            <span class="kb-form-label-text">입소자 3</span>
                          </label>
                        </div>
                        <div class="kb-form-column">
                          <input v-model="trnctAplyIns.aplyDtl[2].cpnnNm" type="text" class="kb-form-control"
                                 placeholder="이름을 입력하세요." />
                        </div>
                        <!--                        <div class="kb-form-column kb-form-column-title">
                          <label class="kb-form-label">
                            <span class="kb-form-label-text"> {{ getRelName(trnctAplyIns.aplyDtl[2].aplcntRel ) }}</span>
                          </label>
                        </div>-->
                        <div class="kb-form-column">
                          <div class="kb-form-dropdown" :class="{ 'is-active': toggles.aply3 }">
                            <button class="kb-form-dropdown-btn" @click="openAply(3)"><strong class="text">{{
                                getRelName(trnctAplyIns.aplyDtl[2].aplcntRel) }}</strong><i
                                class="icon-arrow"></i></button>
                            <div class="kb-form-dropdown-options">
                              <div class="dropdown-option">
                                <ul class="dropdown-option-list">
                                  <li class="dropdown-option-item" v-for="item in familyList" :key="item.code">
                                    <a href="javascript:" class="dropdown-option-link" @click="selectAply(2, item.code)">
                                      <span class="dropdown-option-text">{{ item.label }}</span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="kb-form-column w-220 d-flex justify-content-end">
                          <button type="button" class="kb-btn-text" @click="deleteFamily(2)">
                            <strong class="font-body14B text-red">삭제</strong>
                          </button>
                        </div>
                      </div>
                      <div class="kb-form-row">
                        <div class="kb-form-column kb-form-column-title">
                          <label class="kb-form-label">
                            <span class="kb-form-label-text">입소자 4</span>
                          </label>
                        </div>
                        <div class="kb-form-column">
                          <input v-model="trnctAplyIns.aplyDtl[3].cpnnNm" type="text" class="kb-form-control"
                                 placeholder="이름을 입력하세요." />
                        </div>
                        <!--                        <div class="kb-form-column kb-form-column-title">
                          <label class="kb-form-label">
                            <span class="kb-form-label-text"> {{ getRelName( trnctAplyIns.aplyDtl[3].aplcntRel ) }}</span>
                          </label>
                        </div>-->
                        <div class="kb-form-column">
                          <div class="kb-form-dropdown" :class="{ 'is-active': toggles.aply4 }">
                            <button class="kb-form-dropdown-btn" @click="openAply(4)"><strong class="text">{{ getRelName(
                                trnctAplyIns.aplyDtl[3].aplcntRel) }}</strong><i class="icon-arrow"></i></button>
                            <div class="kb-form-dropdown-options">
                              <div class="dropdown-option">
                                <ul class="dropdown-option-list">
                                  <li class="dropdown-option-item" v-for="item in familyList" :key="item.code">
                                    <a href="javascript:" class="dropdown-option-link" @click="selectAply(3, item.code)">
                                      <span class="dropdown-option-text">{{ item.label }}</span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="kb-form-column w-220 d-flex justify-content-end">
                          <button type="button" class="kb-btn-text" @click="deleteFamily(3)">
                            <strong class="font-body14B text-red">삭제</strong>
                          </button>
                        </div>
                      </div>
                      <div class="kb-form-row">
                        <div class="kb-form-column kb-form-column-title">
                          <label class="kb-form-label">
                            <span class="kb-form-label-text">입소자 5</span>
                          </label>
                        </div>
                        <div class="kb-form-column">
                          <input v-model="trnctAplyIns.aplyDtl[4].cpnnNm" type="text" class="kb-form-control"
                                 placeholder="이름을 입력하세요." />
                        </div>
                        <!--                        <div class="kb-form-column kb-form-column-title">
                          <label class="kb-form-label">
                            <span class="kb-form-label-text"> {{ getRelName(trnctAplyIns.aplyDtl[4].aplcntRel) }}</span>
                          </label>
                        </div>-->
                        <div class="kb-form-column">
                          <div class="kb-form-dropdown" :class="{ 'is-active': toggles.aply5 }">
                            <button class="kb-form-dropdown-btn" @click="openAply(5)"><strong class="text">{{
                                getRelName(trnctAplyIns.aplyDtl[4].aplcntRel) }}</strong><i
                                class="icon-arrow"></i></button>
                            <div class="kb-form-dropdown-options">
                              <div class="dropdown-option">
                                <ul class="dropdown-option-list">
                                  <li class="dropdown-option-item" v-for="item in familyList" :key="item.code">
                                    <a href="javascript:" class="dropdown-option-link" @click="selectAply(4, item.code)">
                                      <span class="dropdown-option-text">{{ item.label }}</span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="kb-form-column w-220 d-flex justify-content-end">
                          <button type="button" class="kb-btn-text" @click="deleteFamily(4)">
                            <strong class="font-body14B text-red">삭제</strong>
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- kb-form-row -->
                    <div class="kb-form-row" v-if="trnctAplyIns.prsnBrcYn == 'Y'">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">이용 인원</span>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <p class="kb-form-text">{{ trnctAplyIns.aplyPeopl }}명</p>
                      </div>
                    </div>
                    <!-- kb-form-row -->
                    <!-- kb-form-row -->
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">특이사항</span>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <textarea v-model="trnctAplyIns.dmndCn" class="kb-form-control"
                                  placeholder="특이사항이 있을 경우 입력해 주세요."></textarea>
                      </div>
                    </div>
                    <!-- //kb-form-row -->
                  </div>
                </section>
                <!-- facility-resve-section -->
                <section class="popup-section facility-resve-section agree-wrap">
                  <header class="section-header">
                    <h4 class="title">신청안내</h4>
                  </header>
                  <div class="segment-box agree-content form-check">
                    <p class="text">
                      <input type="checkbox" class="form-check-input trnctagrees"  id="det_chk_agree_01" v-model="data.trnctDet01" />
                      <label class="text-warning form-check-label" for="det_chk_agree_01"></label>
                      1. 이용일 기준 재직중인 임직원만 신청가능하며, 입소정원은 직원 본인 포함 5인 이내 직계가족, 차량은 1대로 제한</p>
                    <p class="text">
                      <input type="checkbox" class="form-check-input trnctagrees"  id="det_chk_agree_02" v-model="data.trnctDet02"  />
                      <label class="form-check-label" for="det_chk_agree_02"></label>
                      2. 안식년휴가 활용 신청자는 안식년 휴가로 평일 연수시설을 이용하는 직원(이용일 기준 휴직/퇴직직원 제외)</p>
                    <p class="text text-indent">※ 입∙퇴소일을 포함한 전체 일정 안식년 휴가 등록 필수</p>
                    <p class="text">
                      <input type="checkbox" class="form-check-input trnctagrees"  id="det_chk_agree_03" v-model="data.trnctDet03"  />
                      <label class="form-check-label" for="det_chk_agree_03"></label>
                      3. 입소(16:00 ~ 24:00) 퇴소 (10:00) 시간을 준수합니다.</p>
                    <p class="text">
                      <input type="checkbox" class="form-check-input trnctagrees"  id="det_chk_agree_04"  v-model="data.trnctDet04" />
                      <label class="form-check-label" for="det_chk_agree_04"></label>
                      4. 수건 등 세면도구는 지원되지 않으니 개별 지참하시기 바랍니다.</p>
                    <p class="text">
                      <input type="checkbox" class="form-check-input trnctagrees"  id="det_chk_agree_05"  v-model="data.trnctDet05" />
                      <label class="form-check-label" for="det_chk_agree_05"></label>
                      5. 애완동물 동반 입소 금지합니다.</p>
                    <p class="text">
                      <input type="checkbox" class="form-check-input trnctagrees"  id="det_chk_agree_06"  v-model="data.trnctDet06" />
                      <label class="form-check-label" for="det_chk_agree_06"></label>
                      6. 냄새를 유발하는 고기류(생선/삼겹살 등)취사를 제한합니다.</p>
                    <p class="text">
                      <input type="checkbox" class="form-check-input trnctagrees"  id="det_chk_agree_07"  v-model="data.trnctDet07" />
                      <label class="form-check-label" for="det_chk_agree_07"></label>
                      7. 취소적용기준 및 사용제한(당일취소, 무단불참, 본인불참, 객실미정리, 근태 미등록(안식년 휴가 이용자)에 대하여 숙지하였습니다.</p>
                    <p class="text text-indent">※당일취소란? 입소당일 휴일 입소인 경우 직전영업일, 안식년휴가 활용 생활연수 (입소 당일 취소)</p>
                    <p class="text">
                      <input type="checkbox" class="form-check-input trnctagrees"  id="det_chk_agree_08"  v-model="data.trnctDet08" />
                      <label class="form-check-label" for="det_chk_agree_08"></label>
                      8. 본인(동반가족포함) 연수원 이용 중 코로나 의심증상 발현 또는 이용 후 코로나 확진시 연수원 담당자에게 즉시 연락하겠습니다.</p>
                    <p class="text">
                      <input type="checkbox" class="form-check-input trnctagrees"  id="det_chk_agree_09"  v-model="data.trnctDet09" />
                      <label class="form-check-label" for="det_chk_agree_09"></label>
                      9. 연수원 사정에 따라 생활연수 운영이 중단 될 수 있습니다. (예: 공사, 코로나19 등)</p>
                    <p class="text">
                      <input type="checkbox" class="form-check-input trnctagrees"  id="det_chk_agree_10"  v-model="data.trnctDet10" />
                      <label class="form-check-label" for="det_chk_agree_10"></label>
                      10. 생활연수 안내는 리브똑똑으로 발송되오니 앱 설치 및 웹메일 인증 확인 바랍니다.</p>
                  </div>
                  <div class="agree-check">
                    <div class="kb-form-check-notice">
                      <p class="text">본인은 위 안내사항을 준수하고 기 제출한 개인(신용)정보 수집·이용·제공 동의서(임직원용)에 의거 <br/> 각 연수시설 관리업체에 개인식별정보(성명,연락처,신청정보)를 제공함을 동의합니다.</p>
                    </div>
                    <div class="kb-form-check">
                      <input type="checkbox" class="kb-form-check-input" id="chk_agree_01" v-model="data.trnctAgree" />
                      <label class="kb-form-check-label" for="chk_agree_01"><span class="text">동의완료</span></label>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <!-- popup-inner > popup-buttons -->
            <div class="popup-buttons">
              <button class="kb-btn kb-btn-outline kb-btn-lg" @click="goNext('step1')"><span class="text">이전</span></button>
              <a href="javascript:" class="kb-btn kb-btn-primary kb-btn-lg" @click="goNext('step3')"><span
                  class="text">다음</span></a>
            </div>
          </form>
        </div>
        <!-- popup > popup-close -->
        <div class="popup-close">
          <button @click="closeModal" type="button" class="kb-btn-popup-close"><i class="icon-popup-close">팝업닫기</i></button>
        </div>
      </div>
      <!-- //popup -->
    </div>
    <!-- end:popup-container -->


    <!-- begin:popup-container -->
    <!-- 연수원 이용 신청하기 3 -->
    <div class="popup-container" :class="{ 'is-active': trainingInstituteApplyStep3Modal }" >
      <!-- popup -->
      <div class="popup" id="popup-facility-k02">
        <!-- popup > popup-inner -->
        <div class="popup-inner">
          <form @submit.prevent>
            <!-- popup-inner > popup-header -->
            <header class="popup-header">
              <h3 class="title">{{ venue == '1' ? '속초연수원' : '대천연수원' }} 개인신청</h3>
            </header>
            <!-- popup-inner > popup-content -->
            <div class="popup-content">
              <div class="facility-resve">
                <!-- facility-resve-section -->
                <div class="facility-resve-section">
                  <div class="kb-table kb-table-striped">
                    <table>
                      <colgroup>
                        <col />
                      </colgroup>
                      <thead>
                      <tr>
                        <th><span class="th-title">연수원</span></th>
                        <th><span class="th-title">투숙기간</span></th>
                        <th><span class="th-title">인원</span></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td><span class="td-text">{{ venue == '1' ? '속초연수원' : '대천연수원' }}</span></td>
                        <td><span class="td-text">{{ trnctAplyIns.rsvtAplyDd + " ~ " + data.display2 }}</span></td>
                        <td><span class="td-text">성인 {{ trnctAplyIns.aplyPeopl }}명</span></td>
                      </tr>
                      <tr v-if="trnctAplyIns.prsnBrcYn == 'N'">
                        <td colspan="3">
                          <p class="textarea-title">신청사유</p>
                          <textarea v-model="trnctAplyIns.aplyRsn" cols="30" rows="6" class="kb-form-control"
                                    placeholder="특이사항이 있을 경우 입력해 주세요."></textarea>
                        </td>
                      </tr>
                      <!--                      <tr>
                      <td colspan="3">
                        <p class="textarea-title">관리자 메모사항</p>
                        <textarea v-model="trnctAplyIns.mngrMemoCn" cols="30" rows="6" class="kb-form-control" placeholder="관리자에게 전달할 사항을 입력해 주세요."></textarea>
                      </td>
                    </tr>-->

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- popup-inner > popup-buttons -->
            <div class="popup-buttons">
              <button class="kb-btn kb-btn-outline kb-btn-lg" @click="trainingInstituteApplyStep3Modal = false"><span
                  class="text">취소</span></button>
              <a href="javascript:" class="kb-btn kb-btn-primary kb-btn-lg" @click="goNext('step4')"><span
                  class="text">예약</span></a>
            </div>
          </form>
        </div>
        <!-- popup > popup-close -->
        <div class="popup-close">
          <button @click="closeModal" type="button" class="kb-btn-popup-close"><i class="icon-popup-close">팝업닫기</i></button>
        </div>
      </div>
      <!-- //popup -->
    </div>
    <!-- end:popup-container -->

    <!-- [begin::alert-container] -->
    <!-- 연수원 이용 신청하기 4 -->
    <div class="kb-alert-container" :class="{ 'is-active': trainingInstituteApplyStep4Modal }">
      <div class="kb-alert">
        <div class="kb-alert-contents">
          <strong class="kb-alert-title">신청완료</strong>
          <p class="kb-alert-text">연수원 신청이 완료되었습니다.</p>
        </div>
        <div class="kb-alert-actions">
          <router-link :to="{ name: 'DailyHistory' }" class="kb-btn-alert"><span class="text">신청내역 확인</span></router-link>
          <button class="kb-btn-alert" @click="trainingInstituteApplyStep4Modal = false"><span
              class="text">닫기</span></button>
        </div>
      </div>
    </div>
    <!-- [end::alert-container] -->


    <!-- begin:popup-container fclt0-->
    <div class="popup-container" :class="{ 'is-active': fcltStep0Modal }">
      <!-- popup -->
      <div class="popup">
        <!-- popup > popup-inner -->
        <div class="popup-inner">
          <form action="">
            <!-- popup-inner > popup-header -->
            <header class="popup-header">
              <h3 class="title">일산연수원 운동장 이용안내</h3>
            </header>
            <!-- popup-inner > popup-content -->
            <div class="popup-content">
              <article class="popup-section">
                <header class="section-header">
                  <h3 class="title">이용안내</h3>
                </header>
                <div class="segment-box">
                  <p class="text">1. 신청은 3분이내로 완료해 주십시오. 3분안에 신청이 완료되지 않을 경우 자동 취소 처리 됩니다.</p>
                  <p class="text">2. 이용예약은 사용월 전월 둘째 영업일 17시부터 신청 가능합니다.</p>
                  <p class="text">3. 신청자(정 또는 부)는 이용일자 기준으로 월 1회만 이용가능 합니다.</p>
                  <p class="text">4. 당행동호회의 당행직원에 한하여 이용 가능합니다.</p>
                  <p class="text">5. 신청직원 2명 필수 등록 및 참석 상주하여야 하며 이용직원을 인솔 관리 감독하셔야 합니다.</p>
                  <p class="text">6. 개인별 월1회(2시간) 이용 가능합니다.</p>
                  <p class="text">7. 2022.9.21일자 공지사항 등재내용 (운동장 운영 및 패널티)을 확인하시기 바랍니다.</p>
                </div>
              </article>
            </div>
            <!-- popup-inner > popup-buttons -->
            <div class="popup-buttons">
              <button type="button" class="kb-btn kb-btn-outline kb-btn-lg" @click="goFcltNext('cancel')"><span
                  class="text">취소</span></button>
              <a href="javascript:" class="kb-btn kb-btn-primary kb-btn-lg" @click="goFcltNext('step1')"><span
                  class="text">신청</span></a>
            </div>
          </form>
        </div>
        <!-- popup > popup-close -->
        <div class="popup-close">
          <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
        </div>
      </div>
      <!-- //popup -->
    </div>
    <!-- end:popup-container -->


    <!-- begin:popup-container fclt1-->
    <div class="popup-container" :class="{ 'is-active': fcltStep1Modal }">
      <!-- popup -->
      <div class="popup" id="popup-facility-k11">
        <!-- popup > popup-inner -->
        <div class="popup-inner">
          <form @submit.prevent>
            <!-- popup-inner > popup-header -->
            <header class="popup-header">
              <h3 class="title">안내사항 동의서</h3>
            </header>
            <!-- popup-inner > popup-content -->
            <div class="popup-content">

              <!-- section:안내문 -->
              <article class="popup-section">
                <header class="section-header header-divider">
                  <h3 class="title">일산연수원 안내문</h3>
                </header>
                <div class="kb-form-fields kb-form-fields-v2">
                  <!-- field:신청자 1 -->
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label for="" class="kb-form-label"><span class="kb-form-label-text">신청자 1</span></label>
                    </div>
                    <div class="kb-form-column">
                      <span class="kb-form-text">{{ fcltRsvtAplyIns.aplcntDeptNm }} ・ {{ session.jbgdNm }} {{ session.jbpsNm
                        }} ・ {{ fcltRsvtAplyIns.aplcntNm }} | {{ fcltRsvtAplyIns.aplcntId }} </span>
                    </div>
                  </div><!-- field:신청자 2 -->
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label for="" class="kb-form-label"><span class="kb-form-label-text">신청자 2</span></label>
                    </div>
                    <div class="kb-form-column column-whole kb-form-search">
                      <input @keyup.enter="searchUser" v-model="userSearchParam.searchText" type="text"
                             class="kb-form-control" placeholder="직번을 입력하세요." />
                      <button type="button" class="kb-form-search-btn" @click="searchUser"><i
                          class="icon-search"></i></button>
                    </div>

                  </div>
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label for="" class="kb-form-label"><span class="kb-form-label-text">신청자 2 정보</span></label>
                    </div>
                    <div class="kb-form-column">
                      <span class="kb-form-text" v-if="fcltRsvtAplyIns.aplcntId2 != null">{{ fcltRsvtAplyIns.aplcntDeptNm2 }}
                        ・ {{ fcltRsvtAplyIns.aplcntNm2 }} | {{ fcltRsvtAplyIns.aplcntId2 }} </span>
                    </div>
                  </div>

                </div>
                <div class="kb-form-fields-bottom bottom-agree">
                  <p class="text">일산연수원 운동장 사용 관련 연수 시설물의 효율적인 관리와 실질적인 영업 마케팅 추진 및 체육활동 지원을 위해 다음의 내용을 <br />확인하고 위반시
                    사용제한에 동의합니다.</p>
                  <div class="kb-form-check">
                    <input type="checkbox" class="kb-form-check-input" v-model="data.fcltAgree" />
                    <label for="chk_agree_01" class="kb-form-check-label">동의완료</label>
                  </div>
                </div>
              </article>

              <article class="popup-section">
                <div class="kb-table kb-table-bordered-v2">
                  <table>
                    <col style="width:240px" />
                    <col style="width:auto" />
                    <col style="width:300px" />
                    <thead>
                    <tr>
                      <th><span class="th-title">구분</span></th>
                      <th><span class="th-title">운영방식</span></th>
                      <th><span class="th-title">비고</span></th>
                    </tr>
                    </thead>
                    <tbody class="align-left">
                    <tr>
                      <td><span class="td-text">이용대상</span></td>
                      <td>
                        <p class="td-text">당행 동호회 (당행직원에 한함)</p>
                        <p class="td-text text-muted">개인동호회 ∙ 외부팀 초청 경기 불허</p>
                      </td>
                      <td><span class="td-text"></span></td>
                    </tr>
                    <tr>
                      <td><span class="td-text">신청자</span></td>
                      <td><span class="td-text">당행직원 2명 필수 등록</span></td>
                      <td><span class="td-text">신청자는 이용자 관리 감독</span></td>
                    </tr>
                    <tr>
                      <td><span class="td-text">이용시간</span></td>
                      <td><span class="td-text">토요일 08:00 ~ 18:00 (동호회별 1일 1회 사용)</span></td>
                      <td><span class="td-text">2시간 단위 총 5회차</span></td>
                    </tr>
                    <tr>
                      <td><span class="td-text">이용시설</span></td>
                      <td><span class="td-text">대운동장, 화장실, 샤워장</span></td>
                      <td><span class="td-text"></span></td>
                    </tr>
                    <tr>
                      <td><span class="td-text">신청횟수</span></td>
                      <td><span class="td-text">개인별 월 1회</span></td>
                      <td><span class="td-text"></span></td>
                    </tr>
                    <tr>
                      <td><span class="td-text">신청방법</span></td>
                      <td>
                        <div class="text-group-1">
                          <div class="group">
                            <span class="title td-text">신청경로</span>
                            <div class="content">
                              <p class="td-text">생활연수 > 체육시설 > 체육시설 이용신청</p>
                            </div>
                          </div>
                          <div class="group">
                            <span class="title td-text">신청기간</span>
                            <div class="content">
                              <p class="td-text">매월 둘째 영업일 17:00 ~ > 익월 예약화면 Open</p>
                              <p class="td-text text-muted">예) 22년 10월 둘째 영업일 (10.4 수요일) 17:00 > 22년 11월 예약 화면 오픈</p>
                            </div>
                          </div>
                          <div class="group">
                            <span class="title td-text">취소기간</span>
                            <div class="content">
                              <p class="td-text">이용일 3일전 (기준일 초과 취소 불가)</p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td><span class="td-text">22년 10월은 9.26(월) 예약</span></td>
                    </tr>
                    <tr>
                      <td><span class="td-text">패널티</span></td>
                      <td>
                        <div class="text-group-2">
                          <div class="group">
                            <strong class="title td-text">1년 사용제한</strong>
                            <div class="content">
                              <p class="td-text" data-bullet="-">음식물 반입금지 (배달음식 ∙ 컵라면 포함) > 생수,음료,김밥 허용</p>
                              <p class="td-text" data-bullet="-">앰프 및 화기사용,애완동물 출입, 숙소동 및 강의동 출입, 소란행위 등</p>
                              <p class="td-text" data-bullet="-">원형광장 및 연수게시판 앞 주차금지 (정해진 주차구역 주차선 안에 주차)</p>
                              <p class="td-text" data-bullet="-">운동장내 흡연, 운동장 이용 종료 후 주변 정리 미실시</p>
                            </div>
                          </div>
                          <div class="group">
                            <strong class="title td-text">2년 사용제한</strong>
                            <div class="content">
                              <p class="td-text" data-bullet="-">신청자 불참 (2인 모두 참석, 신분증 지참 본인확인 및 상주 필수)</p>
                              <p class="td-text text-muted" data-bullet="">신청자 2인중 1인이라도 본인 확인 불가시 신청직원 2인 모두 패널티 적용</p>
                              <p class="td-text" data-bullet="-">이용대상자 위반 (당행 직원외 이용)</p>
                            </div>
                          </div>
                          <div class="group">
                            <strong class="title td-text">3년 사용제한</strong>
                            <div class="content">
                              <p class="td-text" data-bullet="-">연수원내 취사 및 음주, 질서문란(직원간 다툼 ∙ 폭행 등)</p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td><span class="td-text"></span></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="kb-table-bottom bottom-margin">
                  <p data-bullet="-" class="text text-muted">연수원 출입(정문) 연수원 출입 (정문) 시 직원 신분증 제시</p>
                  <p data-bullet="-" class="text text-muted">휴일 연수원내 경비 및 보안을 위해 직원신분증 미소지자는 연수원 출입 통제</p>
                  <p data-bullet="" class="text text-muted">(<em class="text-muted">단, 신청자가 m-WiseNet을 통해 신분증 미지참자 본인확인
                    지원시 출입 가능</em>)</p>
                  <p data-bullet="-" class="text text-muted">신청자 2인은 이용시간 30분 전까지 연수원 도착. 정문에 비치되어 있는 이용신청서에 서명</p>
                  <p data-bullet="-" class="text text-muted">신청자 2인은 이용시간내 상주하며 이용직원 인솔 관리감독</p>
                  <p data-bullet="-" class="text text-muted">이용시간 준수 및 이용 종료 후 운동장 주변 정리∙정돈 철저</p>
                  <p data-bullet="-" class="text text-muted">연수 진행 또는 전행적인 행사 등 사정에 따라 미운영 또는 예약사항을 취소할 수 있음</p>
                </div>
              </article>

            </div>
            <!-- popup-inner > popup-buttons -->
            <div class="popup-buttons">
              <a @click="goFcltNext('step2')" href="javascript:" class="kb-btn kb-btn-primary kb-btn-lg"><span
                  class="text">동의하기</span></a>
            </div>
          </form>
        </div>
        <!-- popup > popup-close -->
        <div class="popup-close">
          <button @click="closeModal" type="button" class="kb-btn-popup-close"><i class="icon-popup-close">팝업닫기</i></button>
        </div>
      </div>
      <!-- //popup -->
    </div>
    <!-- end:popup-container -->

    <!-- begin:popup-container fclt2-->
    <div class="popup-container" :class="{ 'is-active': fcltStep2Modal }">
      <!-- popup -->
      <div class="popup" id="popup-facility-k11">
        <!-- popup > popup-inner -->
        <div class="popup-inner">
          <form action="">
            <!-- popup-inner > popup-header -->
            <header class="popup-header">
              <h3 class="title">체육시설 예약하기</h3>
            </header>
            <!-- popup-inner > popup-content -->
            <div class="popup-content">

              <div class="popup-intro align-middle">
                <p class="popup-intro-text text-muted">신청자 수정은 안내사항 동의서에서 수정하시고 동의서에 동의하시기 바랍니다.</p>
                <button class="kb-btn kb-btn-secondary kb-btn-sm rounded-lg" type="button" @click="goFcltNext('step1')"><i
                    class="icon-booksearch"></i> <span class="text">안내사항 동의서</span></button>
              </div>

              <!-- section:예약정보 -->
              <article class="popup-section">
                <header class="section-header header-divider">
                  <h3 class="title">예약정보</h3>
                </header>
                <div class="kb-form-fields kb-form-fields-v2">
                  <!-- field:이용일시 -->
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label for="" class="kb-form-label"><span class="kb-form-label-text">이용일시</span></label>
                    </div>
                    <div class="kb-form-column">
                      <span class="kb-form-text">{{ schedule2.startDt.ymdformat }} ・ {{ schedule2.time }}</span>
                    </div>
                  </div>
                  <!-- field:신청자 1 -->
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label for="" class="kb-form-label"><span class="kb-form-label-text">신청자 1</span></label>
                    </div>
                    <div class="kb-form-column w-288">
                      <span class="kb-form-text">{{ fcltRsvtAplyIns.aplcntDeptNm }} ・ {{ fcltRsvtAplyIns.aplcntNm }} | {{
                          fcltRsvtAplyIns.aplcntId }} </span>
                    </div>
                    <div class="kb-form-column kb-form-column-title">
                      <label for="" class="kb-form-label"><span class="kb-form-label-text">신청자 1 생년월일</span></label>
                    </div>
                    <div class="kb-form-column">
                      <input type="text"  maxlength="6" v-model="fcltRsvtAplyIns.brdt" class="kb-form-control w-312"
                             placeholder="생년월일 6자리를 입력하세요." />
                    </div>
                  </div>
                  <!-- field:신청자 2 -->
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label for="" class="kb-form-label"><span class="kb-form-label-text">신청자 2</span></label>
                    </div>
                    <div class="kb-form-column w-288">
                      <span class="kb-form-text">{{ fcltRsvtAplyIns.aplcntDeptNm2 }} ・ {{ fcltRsvtAplyIns.aplcntNm2 }} | {{
                          fcltRsvtAplyIns.aplcntId2 }}</span>
                    </div>
                    <div class="kb-form-column kb-form-column-title">
                      <label for="" class="kb-form-label"><span class="kb-form-label-text">신청자 2 생년월일</span></label>
                    </div>
                    <div class="kb-form-column">
                      <input type="text" maxlength="6" v-model="fcltRsvtAplyIns.brdt2" class="kb-form-control w-312"
                             placeholder="생년월일 6자리를 입력하세요." />
                    </div>
                  </div>
                  <!-- field:이용인원 -->
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label for="" class="kb-form-label"><span class="kb-form-label-text">이용인원</span></label>
                    </div>
                    <div class="kb-form-column w-288">
                      <input v-model="fcltRsvtAplyIns.aplyPeopl" type="number" class="kb-form-control"
                             placeholder="인원수를 입력하세요." />
                    </div>
                    <div class="kb-form-column ps-4">
                      <span class="kb-form-text">명</span>
                    </div>
                  </div>
                  <!-- field:사무실 연락처 -->
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label for="" class="kb-form-label"><span class="kb-form-label-text">신청자1 연락처</span></label>
                    </div>
                    <div class="kb-form-column w-288">
                      <input type="text" class="kb-form-control" maxlength="12" v-model="fcltRsvtAplyIns.aplcntTelno" placeholder="" />
                    </div>
                    <div class="kb-form-column kb-form-column-title">
                      <label for="" class="kb-form-label"><span class="kb-form-label-text">신청자2 연락처</span></label>
                    </div>
                    <div class="kb-form-column">
                      <input type="text" class="kb-form-control w-312" maxlength="12" v-model="fcltRsvtAplyIns.aplcntTelno2"
                             placeholder="" />
                    </div>
                  </div>
                  <!-- field:부점/단체명-->
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label for="" class="kb-form-label"><span class="kb-form-label-text">부점/단체명</span></label>
                    </div>
                    <div class="kb-form-column w-288">
                      <div class="kb-form-dropdown" :class="{ 'is-active': toggles.grpToggle }">
                        <button type="button" @click="openGrpToggle" class="kb-form-dropdown-btn"><span class="text">{{
                            fcltRsvtAplyIns.grpDivNm == null ? '단체구분' : fcltRsvtAplyIns.grpDivNm }}</span><i
                            class="icon-arrow"></i></button>
                        <div class="kb-form-dropdown-options">
                          <div class="dropdown-option">
                            <ul class="dropdown-option-list">
                              <li class="dropdown-option-item" v-for="item in codeList" :key="item.cdSn">
                                <a @click="selectGrp(item)" href="javascript:" class="dropdown-option-link"><span
                                    class="dropdown-option-text">{{ item.cdNm }}</span></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="kb-form-column w-288" v-show="toggles.grpOrClub == true">
                      <input type="text" class="kb-form-control" v-model="fcltRsvtAplyIns.grpNm" placeholder="" />
                    </div>
                    <div class="kb-form-column w-288" v-show="toggles.grpOrClub == false">
                      <div class="kb-form-dropdown" :class="{ 'is-active': toggles.clubToggle }">
                        <button type="button" @click="openClubToggle" class="kb-form-dropdown-btn"><span class="text">{{
                            fcltRsvtAplyIns.grpNm == null ? '둥호회 선택' : fcltRsvtAplyIns.grpNm }}</span><i
                            class="icon-arrow"></i></button>
                        <div class="kb-form-dropdown-options">
                          <div class="dropdown-option">
                            <ul class="dropdown-option-list">
                              <li class="dropdown-option-item" v-for="item in clubList" :key="item.code">
                                <a @click="selectClub(item)" href="javascript:" class="dropdown-option-link"><span
                                    class="dropdown-option-text">{{ item.label }}</span></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="kb-form-column">
                      <span class="kb-form-text-sm text-muted">허위일 경우 향후 6개월간 이용제한</span>
                    </div>
                  </div>
                </div>
              </article>

            </div>
            <!-- popup-inner > popup-buttons -->
            <div class="popup-buttons">
              <a href="javascript:" @click="goFcltNext('step1')" class="kb-btn kb-btn-outline kb-btn-lg"><span
                  class="text">취소</span></a>
              <a href="javascript:" @click="goFcltNext('step3')" class="kb-btn kb-btn-primary kb-btn-lg"><span
                  class="text">예약</span></a>
            </div>
          </form>
        </div>
        <!-- popup > popup-close -->
        <div class="popup-close">
          <button type="button"  @click="goFcltNext('step1')" class="kb-btn-popup-close"><i class="icon-popup-close">팝업닫기</i></button>
        </div>
      </div>
      <!-- //popup -->
    </div>
    <!-- end:popup-container -->


    <!-- begin:popup-container -->
    <div class="popup-container" :class="{ 'is-active': fcltStep3Modal }">
      <!-- popup -->
      <div class="popup" id="popup-facility-k02">
        <!-- popup > popup-inner -->
        <div class="popup-inner">
          <form action="">
            <!-- popup-inner > popup-header -->
            <header class="popup-header">
              <h3 class="title">일산연수원 운동장 이용안내</h3>
            </header>
            <!-- popup-inner > popup-content -->
            <div class="popup-content">
              <article class="popup-section">
                <header class="section-header">
                  <h3 class="title">이용안내</h3>
                </header>
                <div class="segment-box">
                  <p class="text">1. 신청자 2인은 이용시간 30분전까지 연수원 도착, 본인확인 후 정문에 비치되어 있는 이용신청서에 서명</p>
                  <p class="text">&nbsp;</p>
                  <p class="text">2. 신청자 2인은 이용시간 내 상주하며 이용직원 인솔 관리감독</p>
                  <p class="text">(단, 신청자가 m-WiseNet을 통해 신분증 미지참자 본인확인 지원시 출입 가능 > 본인확인 불가시 출입 통제)</p>
                  <p class="text">&nbsp;</p>
                  <p class="text">3. 이용시간 준수 및 이용 종료 후 운동장 주변 정리 ∙ 정돈을 부탁 드립니다.</p>
                </div>
              </article>
            </div>
            <!-- popup-inner > popup-buttons -->
            <div class="popup-buttons">
              <button @click="closeModal" type="button" class="kb-btn kb-btn-outline kb-btn-lg"><span
                  class="text">취소</span></button>
              <a @click="goFcltNext('step4')" href="javascript:" class="kb-btn kb-btn-primary kb-btn-lg"><span
                  class="text">등록</span></a>
            </div>
          </form>
        </div>
        <!-- popup > popup-close -->
        <div class="popup-close">
          <button type="button" @click="closeModal" class="kb-btn-popup-close"><i class="icon-popup-close">팝업닫기</i></button>
        </div>
      </div>
      <!-- //popup -->
    </div>
    <!-- end:popup-container -->


    <!-- [begin::alert-container] -->
    <!-- 연수원 이용 신청하기 4 -->
    <div class="kb-alert-container" :class="{ 'is-active': fcltStep4Modal }">
      <div class="kb-alert">
        <div class="kb-alert-contents">
          <strong class="kb-alert-title">예약완료</strong>
          <p class="kb-alert-text">일산운동장 예약이 완료되었습니다.</p>
        </div>
        <div class="kb-alert-actions">
          <router-link :to="{ name: 'DailyHistory' }" class="kb-btn-alert"><span class="text">신청내역 확인</span></router-link>
          <button class="kb-btn-alert" @click="goFcltNext('cancel')"><span class="text">확인</span></button>
        </div>
      </div>
    </div>
    <!-- [end::alert-container] -->


    <!-- 팝업 -->
    <!--    <div class="layer-container" :class="{ 'is-active' : noticeModal }">-->
    <!--      <div class="layer" id="layer-notice-alert">-->
    <!--        <div class="banner-container">-->
    <!--          <div class="banner-wrapper">-->
    <!--            <div class="banner-item">-->
    <!--              <a href="" class="banner-link">-->
    <!--                <img src="../../../assets/lxp/images/@tmp/@tmp_al.png" alt="" />-->
    <!--              </a>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <button class="banner-close" type="button" @click="noticeModal = false"><span class="text">오늘 하루 보지 않기</span></button>-->
    <!--        </div>-->
    <!--        <button class="layer-close-btn" type="button" @click="noticeModal = false"><i class="icon-close"></i></button>-->
    <!--      </div>-->
    <!--    </div>-->
  </main>
</template>

<script>
import LxpMainHeader from '@/components/common/LxpMainHeader';
import { computed, reactive, ref, watch, onMounted } from 'vue';
import ImageSwiperComponent from '@/components/daily/ImageSwiperComponent';
import { useStore } from "vuex";
import { getItems, isSuccess, lengthCheck,initParams,isTelFormat,isHpTelFormat} from "@/assets/js/util";
import {
  ACT_GET_TRNCT_OPER_YMD_CHECK,
  ACT_GET_USER_LIST,
  ACT_INSERT_FLCT_FSVT_APLY,
  ACT_INSERT_TRNCT_APLY, ACT_INSERT_TRNCT_APLY_FIRST
} from "@/store/modules/trnct/trnct";
import KbDatepickerTime from "@/components/common/KbDatepickerTime";
import { useAlert } from "@/assets/js/modules/common/alert";
import KbCalender from "@/components/common/KbCalender";
import NoticeComponent from "@/components/daily/NoticeComponent";
import HistoryComponent from "@/components/daily/HistoryComponent";
import DirectLinkComponent from "@/components/daily/DirectLinkComponent";
import HistorySportsComponent from "@/components/daily/HistorySportsComponent";

export default {
  name: 'DailyApply',
  components: {

    HistorySportsComponent,
    DirectLinkComponent,
    HistoryComponent,
    NoticeComponent,
    KbCalender,
    KbDatepickerTime,
    ImageSwiperComponent,
    LxpMainHeader,
  },
  setup() {

    onMounted(() => {
      noticeModal.value = true;
    });
    const store = useStore();
    const { showMessage ,showConfirm} = useAlert();
    const historySportsComponent= ref(null);
    const historyComponent= ref(null);


    const trnctPopupNotice = ref(null);
    // trnctPopupNotice.value = `<p class="text">속초·대천연수원(안식년 휴가 활용 포함) 생활연수 안내</p>
    //                         <p class="text">운영 예정일: 24.4.1(월) ~ 24.4.30(화)</p>
    //                         <p class="text text-gold">전산 Open : 24.3.4(월) 21:00 ~</p>`;

    const updatePopupNotice = (ctnt) =>{
      trnctPopupNotice.value= ctnt;
    }
    const session = computed(() => store.state.auth.session);

    const noticeModal = ref(false);

    const codeList = computed(() =>
        store.state.code.codes.filter(code => code.upCd === '2095000' && code.cdNm === '당행 동호회'));

    codeList.value.unshift({ cd: '', cdNm: '단체구분' })
    const trnctAplyIns = reactive({
      aplySecretKey: null,
      rsvtAplyDd: null, //입소일
      trnctAplySn: null,
      prsnBrcYn: 'Y', //개인부점여부
      aplcntId: store.state.auth.session.lrnerId, //신청자ID
      aplcntNm: store.state.auth.session.lrnerNm, //신청자명
      aplcntDeptCd: store.state.auth.session.deptCd, //신청자 부서코드
      aplcntDeptNm: store.state.auth.session.deptNm, //신장자 부서명
      aplcntJbgd: store.state.auth.session.jbgdCd, //신청자직급
      trnctNm: null, //연수원명
      trnctMstSn: null,
      aplyPeopl: 1,  //신청인원
      mlePeopl: null,   //남성인원
      femPeopl: null,   //여성인원
      useRoomCnt: null, //사용객실수
      usePerid: null, //이용기간
      aplyRsn: null, //신청사유
      dmndCn: null, //요청내용
      carNo: null, //차량번호
      mblTelno: null, //휴대전화번호 서무직원번호
      mngrMemoCn: null, //관리자_메모_내용
      emerCt: null, //비상전화번호
      roomTy: 'OR', //숙실형태
      stbyYn: 'N',
      aplyDtl: [
        {
          aplcntRel: 'A1', //신청자 관계
          cpnnNm: store.state.auth.session.lrnerNm, //동반자 명
        },
        {
          aplcntRel: null, //신청자 관계
          cpnnNm: null, //동반자 명
        },
        {
          aplcntRel: null, //신청자 관계
          cpnnNm: null, //동반자 명
        },
        {
          aplcntRel: null, //신청자 관계
          cpnnNm: null, //동반자 명
        },
        {
          aplcntRel: null, //신청자 관계
          cpnnNm: null, //동반자 명
        }
      ]
    })

    const toggles = reactive({
      periodOpen: false,
      aply1: false,
      aply2: false,
      aply3: false,
      aply4: false,
      aply5: false,
      grpToggle: false,
      clubToggle: false,
      grpOrClub: true
    });

    const data = reactive({
      display: null,
      display2: null,
      confirmSche: null,
      fcltAgree: false,
      initTimePicker: true,
      trnctAgree: false,
      trnctPmsAgree: false,
      trnctDet01:false,
      trnctDet02:false,
      trnctDet03:false,
      trnctDet04:false,
      trnctDet05:false,
      trnctDet06:false,
      trnctDet07:false,
      trnctDet08:false,
      trnctDet09:false,
      trnctDet10:false,
    });

    const usePeridOption = ref([]);

    const clubList = ref([]);
    clubList.value.push({ label: "레전드", code: "레전드" });
    clubList.value.push({ label: "본축회", code: "본축회" });
    clubList.value.push({ label: "일레븐", code: "일레븐" });
    clubList.value.push({ label: "중우회", code: "중우회" });
    clubList.value.push({ label: "까치회", code: "까치회" });
    clubList.value.push({ label: "기타", code: "기타" });

    const familyList = ref([]);
    familyList.value.push({ label: "관계 선택", code: null });
    familyList.value.push({ label: "본인", code: "A1" });
    familyList.value.push({ label: "배우자", code: "A2" });
    familyList.value.push({ label: "자녀", code: "A3" });
    familyList.value.push({ label: "본인(부)", code: "A4" });
    familyList.value.push({ label: "본인(모)", code: "A5" });
    familyList.value.push({ label: "배우자(부)", code: "A6" });
    familyList.value.push({ label: "배우자(모)", code: "A7" });
    familyList.value.push({ label: "조부", code: "A8" });
    familyList.value.push({ label: "조모", code: "A9" });

    const openPeriod = () => {
      if (toggles.periodOpen === true) {
        toggles.periodOpen = false;
      } else {
        toggles.periodOpen = true;
      }
    }

    const openGrpToggle = () => {
      if (toggles.grpToggle === true) {
        toggles.grpToggle = false;
      } else {
        toggles.grpToggle = true;
      }
    }

    const openClubToggle = () => {
      if (toggles.clubToggle === true) {
        toggles.clubToggle = false;
      } else {
        toggles.clubToggle = true;
      }
    }

    const openAply = (num) => {
      console.log(num);
      if (toggles[`aply${num}`] === true) {
        toggles[`aply${num}`] = false;
      } else {
        toggles[`aply${num}`] = true;
      }
    }

    const selectAply = (num, type) => {
      toggles[`aply${num}`] = false;


      if (type != "" || type != null) {
        trnctAplyIns.aplyDtl[num].aplcntRel = type;
      }
      toggles.aply1 = false;
      toggles.aply2 = false;
      toggles.aply3 = false;
      toggles.aply4 = false;
      toggles.aply5 = false;
    }

    const deleteFamily = (index) => {
      trnctAplyIns.aplyDtl[index].aplcntRel = null;
      trnctAplyIns.aplyDtl[index].cpnnNm = null;
    }

    const selectUsePeriod = (usePeriod) => {
      console.log("usePeriod", usePeriod)

      if (usePeriod.value == 0) {
        return;
      }

      toggles.periodOpen = false;
      trnctAplyIns.usePerid = usePeriod.value;
      trnctAplyIns.stbyYn = usePeriod.stbyYn;

      trnctAplyIns.aplySecretKey = usePeriod.aplySecretKey;
      trnctAplyIns.roomTy = room.value;

      data.display = usePeriod.label;
      data.display2 = usePeriod.label2;
    }

    const selectGrp = (item) => {
      fcltRsvtAplyIns.grpDivCdDcd = item.cd
      fcltRsvtAplyIns.grpDivNm = item.cdNm;
      toggles.grpToggle = false;

      fcltRsvtAplyIns.grpNm = null;

      //당행동호회를 클릭시
      if (item.cd == '2095004') {
        //
        toggles.grpOrClub = false;

      } else {
        toggles.grpOrClub = true;
      }
    }

    const selectClub = (item) => {
      toggles.clubToggle = false;
      fcltRsvtAplyIns.grpNm = item.label;
    }

    const getRelName = (code) => {
      //본인, 배우자, 자녀, 본인(부), 본인(모),배우자(부),배우자(모),조부,조모
      if (code == 'A1') {
        return '본인'
      } else if (code == 'A2') {
        return '배우자'
      } else if (code == 'A3') {
        return '자녀'
      } else if (code == 'A4') {
        return '본인(부)'
      } else if (code == 'A5') {
        return '본인(모)'
      } else if (code == 'A6') {
        return '배우자(부)'
      } else if (code == 'A7') {
        return '배우자(모)'
      } else if (code == 'A8') {
        return '조부'
      } else if (code == 'A9') {
        return '조모'
      } else {
        return '관계 선택'
      }
    }
    const dateChange = () => {
      getTrnctOperYmdCheck();
    }

    const getTrnctOperYmdCheck = async () => {
      usePeridOption.value = [];
      await store.dispatch(`trnct/${ACT_GET_TRNCT_OPER_YMD_CHECK}`, {
        trnctMstSn: venue.value,
        rsvtAplyDd: schedule.value.startDt.ymdformat,
        prsnBrcYn: isPersonal.value === true ? "Y" : "N",
        roomTy: room.value
      }).then(res => {
        if (lengthCheck(res)) {
          console.log('getTrnctOperYmdCheck...', res);
          usePeridOption.value = [];
          for (let i = 0; i < res.items.length; i++) {
            usePeridOption.value.push(res.items[i]);
          }
          //usePeridOption.value.push({label: '1박 2일 - 05/25(수요일 퇴소) -> 신청가능', value: '1'});


        }
      }).catch((e) => { console.error(e); })
    }


    const trainingArea = ref([
      {
        src: require('../../../assets/lxp/images/daily/sockcho.jpg'),
        title: '속초연수원',
      }
    ]);

    const ptArea = ref([
      {
        src: require('../../../assets/lxp/images/daily/facility.jpg'),
        title: '이미지1',
      }
    ]);

    const studio = ref([
      {
        src: require('../../../assets/lxp/images/@tmp/@tmp_facility_03.jpg'),
        title: '이미지1',
      }, {
        src: require('../../../assets/lxp/images/@tmp/@tmp_facility_03.jpg'),
        title: '이미지1',
      }, {
        src: require('../../../assets/lxp/images/@tmp/@tmp_facility_03.jpg'),
        title: '이미지1',
      }]);

    const peopleCnt = ref(0);
    const selectDate = ref(false);
    const reserveVenue = ref('연수원');
    const venue = ref("1");
    const schedule = ref({
      startDt: '',
      endDt: ''
    });

    const schedule2 = ref({
      startDt: '',
      endDt: '',
      time: ''
    });
    // const room = ref("TR");
    const room = ref(null);

    watch(() => room.value, () => {

      if (trainingInstituteApplyStep1Modal.value == true) {
        toggles.periodOpen = false;
        data.display = null;
        data.display2 = null;
        trnctAplyIns.roomTy = room.value;
        getTrnctOperYmdCheck();
      }

      schedule.value = {
        startDt:'',
        endDt: ''
      };
    })


    watch(trnctAplyIns.aplyDtl, () => {
      trnctAplyIns.aplyPeopl = trnctAplyIns.aplyDtl.filter((v) => { return (v.cpnnNm != null && v.cpnnNm != '') }).length
    });

    const handleAddPeople = () => {
      peopleCnt.value++;
    };

    const handleSubtractPeople = () => {
      if (peopleCnt.value > 0) {
        peopleCnt.value--;
      } else {
        peopleCnt.value = 0;
      }
    };

    const handleCheckInDate = () => {
      selectDate.value = !selectDate.value;
    };

    const handleCheckOutDate = () => {
      selectDate.value = !selectDate.value;
    };

    const isPersonal = ref(true);

    const trainingInstituteApplyStep0Modal = ref(false);
    const trainingInstituteApplyStep1Modal = ref(false);
    const trainingInstituteApplyStep2Modal = ref(false);
    const trainingInstituteApplyStep3Modal = ref(false);
    const trainingInstituteApplyStep4Modal = ref(false);

    const trainingInstituteApplyBtn = (_isPersonal) => {

      const imsiList = [{ id: '1505718', trnctMstSn: '1', 'date': '2023-06-10' },
        { id: '3313257', trnctMstSn: '1', 'date': '2023-09-12' },
        { id: '1642581', trnctMstSn: '1', 'date': '2023-09-17' },
        { id: '2541018', trnctMstSn: '1', 'date': '2024-09-19' },
        { id: '5907692', trnctMstSn: '1', 'date': '2023-10-08' },
        { id: '5906897', trnctMstSn: '1', 'date': '2024-10-16' },
        { id: '2518432', trnctMstSn: '1', 'date': '2023-10-28' },
        { id: '5903708', trnctMstSn: '1', 'date': '2024-11-06' },
        { id: '1550285', trnctMstSn: '1', 'date': '2024-11-28' },
        { id: '3166541', trnctMstSn: '1', 'date': '2024-12-05' },
        { id: '2523809', trnctMstSn: '1', 'date': '2023-12-04' },
        { id: '3160702', trnctMstSn: '1', 'date': '2023-12-01' },
        { id: '3166693', trnctMstSn: '2', 'date': '2023-05-26' },
        { id: '1649041', trnctMstSn: '2', 'date': '2023-05-27' },
        { id: '3172468', trnctMstSn: '2', 'date': '2023-06-09' }]

      const imsi = imsiList.find(item => item.id === store.state.auth.session.lrnerId && item.trnctMstSn === venue.value);
      if (imsi) {
        if (new Date(imsi.date) > new Date()) {
          showMessage('신청 불가');
          return;
        }
      }

      //여기서 한번 클리어 한다
      clearForm();
      isPersonal.value = _isPersonal;

      if (schedule.value.startDt == '') {
        showMessage('숙박날짜를 지정해주세요.');
        return false;
      }

      trainingInstituteApplyStep0Modal.value = false;
      trainingInstituteApplyStep1Modal.value = true;
      trnctAplyIns.prsnBrcYn = isPersonal.value === true ? "Y" : "N";
      trnctAplyIns.rsvtAplyDd = schedule.value.startDt.ymdformat;
      trnctAplyIns.trnctMstSn = venue.value;
      trnctAplyIns.trnctNm = venue.value == '1' ? '속초연수원' : '대천연수원';

      getTrnctOperYmdCheck();
    };

    const clearForm = () => {

      trnctAplyIns.rsvtAplyDd = null;
      trnctAplyIns.prsnBrcYn = null; //개인부점여부
      trnctAplyIns.aplcntId = store.state.auth.session.lrnerId; //신청자ID
      trnctAplyIns.aplcntNm = store.state.auth.session.lrnerNm; //신청자명
      trnctAplyIns.aplcntDeptCd = store.state.auth.session.deptCd, //신청자 부서코드
          trnctAplyIns.aplcntDeptNm = store.state.auth.session.deptNm, //신장자 부서명
          trnctAplyIns.aplcntJbgd = store.state.auth.session.jbgdCd, //신청자직급
          trnctAplyIns.aplcntDeptCd = null; //신청자 부서코드
      trnctAplyIns.aplcntDeptNm = null; //신장자 부서명
      trnctAplyIns.aplcntJbgd = store.state.auth.session.jbgdCd; //신청자직급
      trnctAplyIns.trnctNm = null; //연수원명
      trnctAplyIns.aplyPeopl = 1;  //신청인원
      trnctAplyIns.mlePeopl = null;   //남성인원
      trnctAplyIns.femPeopl = null;   //여성인원
      trnctAplyIns.useRoomCnt = null; //사용객실수
      trnctAplyIns.usePerid = null; //이용기간
      trnctAplyIns.aplyRsn = null; //신청사유
      trnctAplyIns.dmndCn = null; //요청내용
      trnctAplyIns.carNo = null; //차량번호
      trnctAplyIns.mblTelno = null; //휴대전화번호 서무직원번호
      trnctAplyIns.mngrMemoCn = null; //관리자_메모_내용
      trnctAplyIns.emerCt = null; //비상전화번호
      trnctAplyIns.roomTy = 'OR'; //숙실형태
      trnctAplyIns.stbyYn = 'N';

      trnctAplyIns.aplyDtl[0].aplcntRel = 'A1';
      trnctAplyIns.aplyDtl[0].cpnnNm = store.state.auth.session.lrnerNm;

      trnctAplyIns.aplyDtl[1].aplcntRel = null;
      trnctAplyIns.aplyDtl[1].cpnnNm = null;

      trnctAplyIns.aplyDtl[2].aplcntRel = null;
      trnctAplyIns.aplyDtl[2].cpnnNm = null;

      trnctAplyIns.aplyDtl[3].aplcntRel = null;
      trnctAplyIns.aplyDtl[3].cpnnNm = null;

      trnctAplyIns.aplyDtl[4].aplcntRel = null;
      trnctAplyIns.aplyDtl[4].cpnnNm = null;
      trnctAplyIns.trnctMstSn = null;

      data.display = null;
      data.display2 = null;


      data.trnctAgree = false;

      data.trnctDet01 = false;
      data.trnctDet02 = false;
      data.trnctDet03 = false;
      data.trnctDet04 = false;
      data.trnctDet05 = false;
      data.trnctDet06 = false;
      data.trnctDet07 = false;
      data.trnctDet08 = false;
      data.trnctDet09 = false;
      data.trnctDet10 = false;

    }

    const goNext = async (step) => {

      if (step === 'step0') {
        clearFcltAplyInsParams();
        clearForm();
        trainingInstituteApplyStep0Modal.value = true;
        trainingInstituteApplyStep1Modal.value = false;
        trainingInstituteApplyStep2Modal.value = false;
      } else if (step === 'step1') {
        trainingInstituteApplyStep0Modal.value = false;
        trainingInstituteApplyStep1Modal.value = true;
        trainingInstituteApplyStep2Modal.value = false;
      } else if (step === 'step2') {

        if (trnctAplyIns.usePerid == null || trnctAplyIns.usePerid == '') {
          showMessage('이용기간을 선택해주세요');
          return;
        }

        //해당기간으로 먼저 입소처리 후 신청서 작성으로 개선 2024.08.28
        let confirmSche = "▣ 연수원:" + (venue.value == '1' ? '속초연수원' : '대천연수원') + "<br/>▣ 이용기간:" + (trnctAplyIns.rsvtAplyDd + " ~<br/>" + data.display2);

        showConfirm(`해당기간으로 신청하시겠습니까?<br/><br/>${confirmSche}`, async()=>{

          await store.dispatch(`trnct/${ACT_INSERT_TRNCT_APLY_FIRST}`, trnctAplyIns).then(res => {

            if (isSuccess(res)) {
              showMessage("신청이 접수되었습니다.<br/>다음 화면에서 이용정보를 입력하셔야 신청이 완료됩니다.", ()=>{
                trainingInstituteApplyStep0Modal.value = false;
                trainingInstituteApplyStep1Modal.value = false;
                trainingInstituteApplyStep2Modal.value = true;
                trnctAplyIns.trnctAplySn = res.trnctAplySn;
              });
            } else {
              console.error(res);
              showMessage(`연수원 신청을 하실 수 없습니다. 사유를 확인하시고 이용기간 변경 등 다시 시도하시기 바랍니다.<br/><br/>☞사유: ${res.errMessage}`, ()=>{
                goNext("step0");
              });
            }
          }).catch((e) => {
            console.error(e);
          })


        });

      } else if (step === 'step3') {

        if (data.trnctAgree == false||
            !(data.trnctDet01&&
                data.trnctDet02&&
                data.trnctDet03&&
                data.trnctDet04&&
                data.trnctDet05&&
                data.trnctDet06&&
                data.trnctDet07&&
                data.trnctDet08&&data.trnctDet09&&data.trnctDet10)
            ) {
          showMessage('각 항목의 유의사항을 확인하시고 "동의완료"를 선택해주시기 바랍니다');
          return;
        }

        if (trnctAplyIns.emerCt == "" || trnctAplyIns.emerCt == null) {
          showMessage('비상연락처를 입력해주세요');
          return;
        }

        if(!isTelFormat(trnctAplyIns.emerCt) && !isHpTelFormat(trnctAplyIns.emerCt) ){
          showMessage('비상연락처가 잘못되었습니다.');
          return false;
        }

        trainingInstituteApplyStep0Modal.value = false;
        trainingInstituteApplyStep2Modal.value = false;
        trainingInstituteApplyStep3Modal.value = true;
      } else {
        //여기서 실제 예약이 이루어 진다
        showConfirm({
          text: '예약 신청 하시겠습니까?',
          callback: async () => {
            trainingInstituteApplyStep0Modal.value = false;
            trainingInstituteApplyStep3Modal.value = false;
            await saveTrnctAply();
          }
        });
      }
    }

    const fcltStep0Modal = ref(false);
    const fcltStep1Modal = ref(false);
    const fcltStep2Modal = ref(false);
    const fcltStep3Modal = ref(false);
    const fcltStep4Modal = ref(false);

    const goFcltNext = async (step) => {

      if (step === 'step0') {
        trainingInstituteApplyStep0Modal.value = false;
        fcltStep0Modal.value = true;
        fcltStep1Modal.value = false;
        fcltStep2Modal.value = false;
        fcltStep3Modal.value = false;
        fcltStep4Modal.value = false;

      } else if (step === 'step1') {


        /*if(fcltRsvtAplyIns.aplcntId == null  || fcltRsvtAplyIns.aplcntId == ''){
          showMessage('신청자를 선택해주세요.');
          return;
        }
        if(fcltRsvtAplyIns.birthDay == null || fcltRsvtAplyIns.birthDay == ''){
          showMessage('주민번호 앞자리를 입력해주세요');
          return;
        }*/
        fcltStep0Modal.value = false;
        fcltStep1Modal.value = true;
        fcltStep2Modal.value = false;
        fcltStep3Modal.value = false;
        fcltStep4Modal.value = false;
      } else if (step === 'step2') {

        if (fcltRsvtAplyIns.aplcntId2 == null || fcltRsvtAplyIns.aplcntId2 == '' ) {
          showMessage('신청자2를 선택해주세요.');
          return;
        }

        if ( fcltRsvtAplyIns.aplcntId==fcltRsvtAplyIns.aplcntId2  ) {
          showMessage('신청자2는 신청자1과 다른사람을 입력해주십시오 선택해주세요.');
          return;
        }


        if (data.fcltAgree == false) {
          showMessage('동의완료를 체크 해주시기 바랍니다.');
          return;
        }

        fcltStep0Modal.value = false;
        fcltStep1Modal.value = false;
        fcltStep2Modal.value = true;
        fcltStep3Modal.value = false;
        fcltStep4Modal.value = false;
      } else if (step === 'step3') {

        if (fcltRsvtAplyIns.brdt == null || fcltRsvtAplyIns.brdt == '') {
          showMessage('신청자1 생년월일 6자리를 입력해주세요');
          return;
        }

        if (fcltRsvtAplyIns.brdt2 == null || fcltRsvtAplyIns.brdt2 == '') {
          showMessage('신청자2 생년월일 6자리를 입력해주세요');
          return;
        }

        if (fcltRsvtAplyIns.aplyPeopl == null || fcltRsvtAplyIns.aplyPeopl == '' || fcltRsvtAplyIns.aplyPeopl == 0) {
          showMessage('이용인원을 입력해주세요');
          return;
        }

        if (fcltRsvtAplyIns.grpDivCdDcd == null || fcltRsvtAplyIns.grpDivCdDcd == '') {
          showMessage('단체를 선택해주세요');
          return;
        }

        if (fcltRsvtAplyIns.aplcntTelno == null || fcltRsvtAplyIns.aplcntTelno == '') {
          showMessage('신청자 1 연락처를 입력해주세요');
          return;
        }

        if(!isTelFormat(fcltRsvtAplyIns.aplcntTelno) && !isHpTelFormat(fcltRsvtAplyIns.aplcntTelno) ){
          showMessage('신청자 1 연락처가 잘못되었습니다.');
          return false;
        }

        if (fcltRsvtAplyIns.aplcntTelno2 == null || fcltRsvtAplyIns.aplcntTelno2 == '') {
          showMessage('신청자 2 연락처를 입력해주세요');
          return;
        }

        if(!isTelFormat(fcltRsvtAplyIns.aplcntTelno2) && !isHpTelFormat(fcltRsvtAplyIns.aplcntTelno2) ){
          showMessage('신청자 2 연락처가 잘못되었습니다.');
          return false;
        }

        if (fcltRsvtAplyIns.aplcntTelno == fcltRsvtAplyIns.aplcntTelno2) {
          showMessage('신청자 1, 신청자 2 연락처를 동일하게 입력할 수 없습니다.');
          return;
        }

        if (fcltRsvtAplyIns.grpNm == null || fcltRsvtAplyIns.grpNm == '') {
          showMessage('단체명을 입력해주세요.');
          return;
        }

        //여기가 예약하는곳
        //await saveFcltRsvtAply();
        fcltStep0Modal.value = false;
        fcltStep1Modal.value = false;
        fcltStep2Modal.value = false;
        fcltStep3Modal.value = true;
        fcltStep4Modal.value = false;
      } else if (step === 'step4') {
        //여기서 실제 예약이 이루어 진다
        showConfirm({
          text: '예약 신청 하시겠습니까?',
          callback: async () => {
            await saveFcltRsvtAply();
          }
        });

      } else {
        clearFcltAplyIns();
        fcltStep0Modal.value = false;
        fcltStep1Modal.value = false;
        fcltStep2Modal.value = false;
        fcltStep3Modal.value = false;
        fcltStep4Modal.value = false;
      }
    }
    const clearFcltAplyInsParams = () =>{

      initParams(fcltRsvtAplyIns);
      fcltRsvtAplyIns.fcltDivCdDcd = '2094001';
      fcltRsvtAplyIns.aplcntId = store.state.auth.session.lrnerId;
      fcltRsvtAplyIns.aplcntNm = store.state.auth.session.lrnerNm;
      fcltRsvtAplyIns.aplcntDeptCd = store.state.auth.session.deptCd;
      fcltRsvtAplyIns.aplcntDeptNm = store.state.auth.session.deptNm;
      fcltRsvtAplyIns.part01RsvtYn = 'N';
      fcltRsvtAplyIns.part02RsvtYn = 'N';
      fcltRsvtAplyIns.part03RsvtYn = 'N';
      fcltRsvtAplyIns.part04RsvtYn = 'N';
      fcltRsvtAplyIns.part05RsvtYn = 'N';
      fcltRsvtAplyIns.aplyYmd       =null;
      schedule2.value.time='';


    }

    const saveTrnctAply = async () => {
      if (trnctAplyIns.prsnBrcYn == 'N') {

        if (trnctAplyIns.mlePeopl == null || trnctAplyIns.mlePeopl == '') {
          trnctAplyIns.mlePeopl = 0;
        }

        if (trnctAplyIns.femPeopl == null || trnctAplyIns.femPeopl == '') {
          trnctAplyIns.femPeopl = 0;
        }
        trnctAplyIns.aplyPeopl = parseInt(trnctAplyIns.mlePeopl) + parseInt(trnctAplyIns.femPeopl);
      }

      trnctAplyIns.roomTy = room.value;

      await store.dispatch(`trnct/${ACT_INSERT_TRNCT_APLY}`, trnctAplyIns).then(res => {
        console.log(isSuccess(res))
        if (isSuccess(res)) {
          trainingInstituteApplyStep4Modal.value = true;
          // showMessage('연수원 신청이 완료 되었습니다.');
          historyComponent.value.getTrcntAply();
        } else {
          let msg = res.errMessage;
          showMessage(`연수원 신청이 불가능 합니다. 사유를 확인하시고 다시 신청하시기 바랍니다.<br/><br/>사유: ${msg}`);
        }
      }).catch((e) => {
        console.error(e);
      })
    }



    watch(() => venue.value, () => {

      //연수원 변경시 룸 타입 초기화
      room.value = null;

      if (venue.value == 1) {
        trainingArea.value = [{
          src: require('../../../assets/lxp/images/daily/sockcho.jpg'),
          title: '속초연수원',
        }];

      } else {
        trainingArea.value = [{
          src: require('../../../assets/lxp/images/daily/daecheon.jpg'),
          title: '대천연수원'
        }];
      }

      //여기서 이미지 변경 처리


      schedule.value = {
        startDt: '',
        endDt: ''
      };
    });

    const clickTime = (item) => {
      console.log('time click');
      console.log(item);

      if (item.value == 1) {
        fcltRsvtAplyIns.part01RsvtYn = 'Y';
      } else if (item.value == 2) {
        fcltRsvtAplyIns.part02RsvtYn = 'Y';
      } else if (item.value == 3) {
        fcltRsvtAplyIns.part03RsvtYn = 'Y';
      } else if (item.value == 4) {
        fcltRsvtAplyIns.part04RsvtYn = 'Y';
      } else if (item.value == 5) {
        fcltRsvtAplyIns.part05RsvtYn = 'Y';
      }
      schedule2.value.time = item.time;
      fcltRsvtAplyIns.aplyYmd = schedule2.value.startDt.ymdformat;
      data.initTimePicker = false;
      data.initTimePicker = true;
      goFcltNext('step0');
    }

    const userList = ref([]);
    const userSearchParam = reactive({
      searchText: null,
      totalCount: 0
    });

    const searchUser = () => {

      if (userSearchParam.searchText == null) {
        showMessage('직번을 입력해주세요.');
        return;
      }

      if (userSearchParam.searchText == fcltRsvtAplyIns.aplcntId) {
        showMessage('신청자1과 다른 직번을 입력해주십시오.');
        userSearchParam.searchText = '';
        return;
      }


      store.dispatch(`trnct/${ACT_GET_USER_LIST}`, {
        lrnerId: userSearchParam.searchText,
        pageNo: 1,
        pageSize: 100
      }).then(res => {
        if (lengthCheck(res)) {
          userList.value = getItems(res);
          console.log(userList.value)

          fcltRsvtAplyIns.aplcntId2 = userList.value[0].lrnerId;
          fcltRsvtAplyIns.aplcntNm2 = userList.value[0].lrnerNm;
          fcltRsvtAplyIns.aplcntDeptCd2 = userList.value[0].deptCd;
          fcltRsvtAplyIns.aplcntDeptNm2 = userList.value[0].deptNm;

        } else {
          showMessage('해당 직번이 없습니다.');
          userList.value = [];
          userSearchParam.totalCount = 0;

          fcltRsvtAplyIns.aplcntId2 = null;
          fcltRsvtAplyIns.aplcntNm2 = null;
          fcltRsvtAplyIns.aplcntDeptCd2 = null;
          fcltRsvtAplyIns.aplcntDeptNm2 = null;
        }
      }).catch(e => {
        console.error(e);
      });
    }

    const clearSearch = () => {
      console.log('초기화')
      userSearchParam.searchText = null;
    }

    const fcltRsvtAplyIns = reactive({
      fcltDivCdDcd: '2094001',
      aplyYmd: null,
      aplcntId: store.state.auth.session.lrnerId,
      aplcntNm: store.state.auth.session.lrnerNm, //신청자명
      aplcntDeptCd: store.state.auth.session.deptCd, //신청자 부서코드
      aplcntDeptNm: store.state.auth.session.deptNm, //신장자 부서명
      aplcntId2: null,
      aplcntNm2: null, //신청자명
      aplcntDeptCd2: null, //신청자 부서코드
      aplcntDeptNm2: null, //신장자 부서명
      grpNm: null,
      aplyPeopl: null,
      part01RsvtYn: 'N',
      part02RsvtYn: 'N',
      part03RsvtYn: 'N',
      part04RsvtYn: 'N',
      part05RsvtYn: 'N',
      mngrMemo: null,
      hoTelno: null,
      hpTelno: null,
      displayTitle: null,
      brdt: null,
      brdt2: null,
      grpDivCdDcd: null,
      grpDivNm: null,
      aplcntTelno: null,
      aplcntTelno2: null
    });

    const clearFcltAplyIns = () => {
      fcltRsvtAplyIns.fcltDivCdDcd = '2094001';
      fcltRsvtAplyIns.aplyYmd = null;
      fcltRsvtAplyIns.aplcntId = store.state.auth.session.lrnerId;
      fcltRsvtAplyIns.aplcntNm = store.state.auth.session.lrnerNm;
      fcltRsvtAplyIns.aplcntDeptCd = store.state.auth.session.deptCd;
      fcltRsvtAplyIns.aplcntDeptNm = store.state.auth.session.deptNm;
      fcltRsvtAplyIns.aplcntId2 = null;
      fcltRsvtAplyIns.aplcntNm2 = null;
      fcltRsvtAplyIns.aplcntDeptCd2 = null;
      fcltRsvtAplyIns.aplcntDeptNm2 = null;
      fcltRsvtAplyIns.brdt = null;
      fcltRsvtAplyIns.brdt2 = null;
      fcltRsvtAplyIns.grpDivCdDcd = null;
      fcltRsvtAplyIns.grpDivNm = null;
      fcltRsvtAplyIns.grpNm = null;
      fcltRsvtAplyIns.aplyPeopl = null;
      fcltRsvtAplyIns.part01RsvtYn = 'N';
      fcltRsvtAplyIns.part02RsvtYn = 'N';
      fcltRsvtAplyIns.part03RsvtYn = 'N';
      fcltRsvtAplyIns.part04RsvtYn = 'N';
      fcltRsvtAplyIns.part05RsvtYn = 'N';
      fcltRsvtAplyIns.mngrMemo = null;
      fcltRsvtAplyIns.aplcntTelno = null;
      fcltRsvtAplyIns.aplcntTelno2 = null;
      fcltRsvtAplyIns.displayTitle = null;

      fcltStep0Modal.value = false;
      fcltStep1Modal.value = false;
      fcltStep2Modal.value = false;
      fcltStep3Modal.value = false;
      fcltStep4Modal.value = false;

      clearSearch();


      data.fcltAgree = false;

    }

    const saveFcltRsvtAply = async () => {
      await store.dispatch(`trnct/${ACT_INSERT_FLCT_FSVT_APLY}`, fcltRsvtAplyIns).then(res => {
        if (isSuccess(res)) {
          clearFcltAplyIns();
          showMessage("신청이 완료되었습니다.");
          historySportsComponent.value.getFcltAply();
        } else {
          let errMessage = '월 신청 회수 (월1회)를 초과하였습니다.';
          if (res.errResultCode == -1) {
            errMessage = '선택하신 동호회는 해당일 다른 회차 예약을 진행 또는 신청 중입니다.';
          }
          showMessage(errMessage);
        }
      }).catch((e) => { console.error(e); })
    }

    const radioClick = (item) => {
      console.log(item);
      fcltRsvtAplyIns.aplcntId = item.lrnerId;
      fcltRsvtAplyIns.aplcntNm = item.lrnerNm;
      fcltRsvtAplyIns.aplcntDeptCd = item.deptCd;
      fcltRsvtAplyIns.aplcntDeptNm = item.deptNm;
    }

    const closeModal = () => {
      fcltStep0Modal.value = false;
      fcltStep1Modal.value = false;
      fcltStep2Modal.value = false;
      fcltStep3Modal.value = false;
      fcltStep4Modal.value = false;
      trainingInstituteApplyStep0Modal.value = false;
      trainingInstituteApplyStep1Modal.value = false;
      trainingInstituteApplyStep2Modal.value = false;
      trainingInstituteApplyStep3Modal.value = false;
      trainingInstituteApplyStep4Modal.value = false;
      clearFcltAplyIns();
      clearForm();
    }
    watch(() => fcltRsvtAplyIns.aplcntTelno, () => {
      fcltRsvtAplyIns.aplcntTelno = (fcltRsvtAplyIns.aplcntTelno==null? "": fcltRsvtAplyIns.aplcntTelno).replace(/[^0-9]/g, '')


    })
    watch(() => fcltRsvtAplyIns.aplcntTelno2, () => {
      fcltRsvtAplyIns.aplcntTelno2 = (fcltRsvtAplyIns.aplcntTelno2==null? "": fcltRsvtAplyIns.aplcntTelno2).replace(/[^0-9]/g, '')
    })

    watch(() => fcltRsvtAplyIns.brdt, () => {
      fcltRsvtAplyIns.brdt = (fcltRsvtAplyIns.brdt==null? "": fcltRsvtAplyIns.brdt).replace(/[^0-9]/g, '')
    })


    watch(() => fcltRsvtAplyIns.brdt2, () => {
      fcltRsvtAplyIns.brdt2 = (fcltRsvtAplyIns.brdt2==null? "": fcltRsvtAplyIns.brdt2).replace(/[^0-9]/g, '')
    })


    return {
      trainingArea,
      ptArea,
      studio,
      venue,

      peopleCnt,
      reserveVenue,
      handleAddPeople,
      handleSubtractPeople,
      handleCheckInDate,
      handleCheckOutDate,
      selectDate,
      schedule,
      schedule2,
      isPersonal,
      trainingInstituteApplyBtn,
      trainingInstituteApplyStep1Modal,
      trainingInstituteApplyStep2Modal,
      trainingInstituteApplyStep3Modal,
      trainingInstituteApplyStep4Modal,
      room,
      goNext,
      session,
      toggles,
      dateChange,
      usePeridOption,
      selectUsePeriod,
      openPeriod,
      data,
      trnctAplyIns,
      openAply,
      selectAply,
      getRelName,
      clearForm,
      clickTime,
      goFcltNext,
      fcltStep0Modal,
      fcltStep1Modal,
      fcltStep2Modal,
      fcltStep3Modal,
      fcltStep4Modal,
      searchUser,
      clearSearch,
      userSearchParam,
      userList,
      saveFcltRsvtAply,
      fcltRsvtAplyIns,
      radioClick,
      trainingInstituteApplyStep0Modal,
      closeModal,
      codeList,
      openGrpToggle,
      selectGrp,
      familyList,
      deleteFamily,
      noticeModal,
      clubList,
      openClubToggle,
      selectClub,
      historySportsComponent,
      historyComponent,
      trnctPopupNotice,
      updatePopupNotice
    };
  },
};
</script>
<style scoped>

.form-check-input:checked {
  background-color: #fdbb01;
  border-color: #f5b992;
}

</style>